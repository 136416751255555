<template>
  <div v-loading="searchLoading">
    <el-table
      ref="tableRef"
      :data="dataList"
      :row-style="rowClass"
      :row-class-name="rowClassName"
      :span-method="arraySpanMethod"
      :show-header="showHeader"
      fit
      class="el-pagination-table"
      @row-dblclick="toggleRowExpansion"
      @sort-change="c => sortChange(c)"
      @selection-change="handleSelectionChange">
      <slot/>
    </el-table>
    <el-pagination
      v-if="!isDevice && pagination !== null && pagination.totalCount && pagination.totalCount > 0"
      id="table_pagination"
      :current-page="pagination.pageNumber"
      :page-size="pagination.pageSize"
      :total="pagination.totalCount"
      class="pagination-panel"
      layout="total, sizes, ->, prev, pager, next"
      @size-change="(size) => handleCurrentChange(size, null)"
      @current-change="(number) => handleCurrentChange(null, number)"/>
    <DevicePagination
      v-else-if="isDevice && pagination !== null"
      id="table_device_pagination"
      :previous-cursor="pagination.previousCursor || ''"
      :next-cursor="pagination.nextCursor || ''"
      :current-page="pagination.pageNumber || 1"
      class="pagination-panel"
      layout="total, ->, prev, pager, next, jumper"
      @current-change="handleDeviceCurrentChange"/>
    <div v-else class="pagination-panel" style="height: 33px; display: block"/>
  </div>
</template>

<script lang="ts">
import { PropType, computed, defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'PaginationTable',
  props: {
    data: {
      type: Array as PropType<Record<string, any>[]>,
      required: true
    },
    pagination: {
      type: Object,
      required: true
    },
    searchFunction: {
      type: Function,
      required: true
    },
    selectionCondition: {
      type: Function,
      default: () => (row) => false
    },
    searchLoading: {
      type: Boolean,
      default: () => false
    },
    sortColumn: {
      type: Array,
      default: () => []
    },
    showHeader: {
      type: Boolean,
      default: () => true
    },
    rowClassName: {
      type: Function as PropType<(row: any, rowIndex: number) => string | null>,
      default: () => ({ row, rowIndex }) => null
    },
    arraySpanMethod: {
      type: Function as PropType<(data: {
        row: any
        rowIndex: number
        column: any
        columnIndex: number
      }) => number[] | { rowspan: number; colspan: number } >,
      default: () => ({ row, column, rowIndex, columnIndex }) => null
    },
    isDevice: {
      type: Boolean,
      default: () => false
    }
  },
  setup(props, { emit }) {
    const tableRef = ref(null)

    const dataList = computed(() => {
      props.data.forEach(d => { d.$selection = false })
      return props.data
    })

    const toggleRowExpansion = (row) => {
      tableRef.value.toggleRowExpansion(row)
    }

    const handleSelectionChange = (rows) => {
      props.data.forEach(d => {
        if (props.selectionCondition(d) === false) {
          d.$selection = false
          tableRef.value.toggleRowSelection(d, false)
        } else {
          d.$selection = rows.indexOf(d) !== -1
        }
      })
      emit('selectionChange', rows)
    }

    const handleCurrentChange = async (pageSize: number, pageNumber: number) => {
      props.pagination.pageSize = pageSize || props.pagination.pageSize
      if (pageSize) {
        props.pagination.pageNumber = 1
      } else {
        props.pagination.pageNumber = pageNumber || props.pagination.pageNumber
      }
      emit('update:pagination', props.pagination)
      emit('update:searchLoading', true)
      await props.searchFunction(() => { })
      emit('update:searchLoading', false)
    }

    const handleDeviceCurrentChange = async (pageNumber: number, cursor: any, paging) => {
      props.pagination.pageNumber = pageNumber
      if (paging > 1) {
        props.pagination.nextCursor = cursor
      } else {
        props.pagination.previousCursor = cursor
      }
      props.pagination.paging = paging
      emit('update:pagination', props.pagination)
      emit('update:searchLoading', true)
      await props.searchFunction(() => { })
      emit('update:searchLoading', false)
    }

    const sortChange = async (c) => {
      if (props.isDevice) {
        return
      }
      if (c.order) {
        emit('update:sortColumn', [c.column.sortBy + ':' + c.order])
      } else {
        emit('update:sortColumn', [])
      }
      props.pagination.pageNumber = 1
      emit('update:searchLoading', true)
      await props.searchFunction(() => { })
      emit('update:searchLoading', false)
    }

    const rowClass = (row) => {
      if (row.$selection === true) {
        return { 'background-color': '#F4F7FA' }
      }
    }

    return {
      tableRef,
      dataList,
      toggleRowExpansion,
      handleSelectionChange,
      handleCurrentChange,
      handleDeviceCurrentChange,
      sortChange,
      rowClass
    }
  }
})
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .el-pager li.active {
    color: #007BC2;
  }
  /*総計と件数切替プルダウンを左に寄せる*/
  .el-pagination {
    width: 100%;
    text-align: right;
    .el-pagination__sizes {
      float: left;
    }
    .el-pagination__total {
      float: left;
    }
  }
  .pagination-panel {
    margin-top: 0;
    border: 0px;
  }
  .el-pagination-table {
    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    ::-webkit-scrollbar-thumb{
      background-color: #bbb;
      -webkit-border-radius: 5px;
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:vertical:hover{
      background-color: #999;
    }
    ::-webkit-scrollbar-thumb:vertical:active{
      background-color: #888;
    }
    ::-webkit-scrollbar-button{
      display: none;
    }
    ::-webkit-scrollbar-track{
      background-color: #f1f1f1;
    }
  }
</style>
