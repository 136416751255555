<template>
  <div class="cell-content"
  @click.stop
  @mouseover="state.isHovering = true"
  @mouseleave="state.isHovering = false">
    <div v-if="state.editing">
      <el-input
        ref="columnValueInput"
        v-model="state.inputText"
        style="width: 150px"
        clearable
        @input="handleInput"
        @blur="finishEdit"
        @keyup.enter="onEnter"
        @compositionstart="state.isComposing = true"
        @compositionend="onCompositionEnd"
      />
    </div>
    <div v-else  class="cell-not-edit-state" @click="handleClick">
      <span class="column-text">
        {{ value }}
      </span>
      <el-icon v-show="state.isHovering" class="column-icon" color="#006EAD">
        <Edit />
      </el-icon>
    </div>
  </div>
</template>

<script lang="ts">
import { nextTick } from '@vue/runtime-dom'
import { Ref, defineComponent, inject, reactive, ref, watch } from 'vue'

export default defineComponent({
  props: {
    value: String,
    valueLength: Number,
    rowId: String,
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    // App.vueでprovideした、グローバルで管理する状態を受け取る
    const isTableValueEditing = inject<Ref<boolean>>('isEditingValue')
    const columnValueInput = ref(null)
    const state = reactive({
      editing: false,
      inputText: '',
      isHovering: false,
      isComposing: false,
      isFinishing: false
    })

    watch(() => state.editing, (newValue) => {
      // テーブル値編集状態(グローバル状態)の切り替え
      isTableValueEditing.value = newValue
    })

    // 制限文字数を超える場合は制限文字数に丸める
    const handleInput = () => {
      if (state.inputText.length > props.valueLength) {
        state.inputText = state.inputText.substring(0, props.valueLength)
      }
    } 
    // 他の行が編集中でない(かつ押下された行も編集中でない)場合にのみ、押下された行を編集モードにする
    const handleClick = () => {
      if (!isTableValueEditing.value) {
        state.editing = true
        state.inputText = props.value
        nextTick(() => {
          columnValueInput.value.focus()
        })
      }
    }
    // 編集モードを終了し、グローバル状態を更新する
    const finishEdit = () => {
      // @blurと@keyup.enterで2回実行されないよう、isFinishing flagで判定する
      if (!state.isFinishing) {
        state.isFinishing = true
        state.editing = false
        emit('update:value', state.inputText)
        nextTick(() => {
          state.isFinishing = false
        })
      }
    }
    const onEnter = () => {
      if (!state.isComposing) {
        finishEdit()
      }
    }
    const onCompositionEnd = () => {
      setTimeout(() => {
        state.isComposing = false
      }, 300)
    }

    return {
      state,
      columnValueInput,
      handleClick,
      handleInput,
      finishEdit,
      onEnter,
      onCompositionEnd
    }
  }
})
</script>

<style scoped>
.cell-content {
  cursor: pointer;
  display: inline-flex !important;
  align-items: center;
  flex-direction: row;
  /* アプリ全体でのポインタイベント無効化を、このコンポーネント内でのみ対象外にする */
  pointer-events: auto;
}
.cell-not-edit-state {
  width: 150px;
  height: 20px;
  display: flex;
  align-items: center;
}
.column-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 132px;
}
span.column-text {
  &:hover {
    color: #006EAD !important;
  }
}
.column-icon {
  flex-shrink: 0;
  display: flex;
}
</style>