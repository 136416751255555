import Layout from '../../../layout/Layout.vue'

const userHelp = [
  {
    path: '/help/list',
    name: 'Help_Main',
    component: () => import('@/views/help/main/help_main.vue'),
    hidden: true,
    meta: { title: 'ヘルプ', svg: 'system', roles: ['USER_ADMIN', 'USER_OPR'], authorities: ['AUTH_HELP_LIST'] }
  },
  {
    path: '/help/show',
    name: 'Help_Show',
    hidden: true,
    component: () => import('@/views/help/detail/help_show.vue'),
    meta: {
      title: ' ヘルプ詳細',
      activeMenu: { path: '/help/list', title: 'ヘルプ' },
      roles: ['USER_ADMIN', 'USER_OPR'],
      authorities: ['AUTH_HELP_READ']
    }
  }
]

const userHelpRouter = {
  path: '/user/help',
  component: Layout,
  name: 'UserHelp',
  meta: { title: 'ヘルプ', svg: 'system' },
  children: [
    ...userHelp
  ]
}

export default userHelpRouter
