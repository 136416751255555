<template>
  <div id="app" :class="rootClass">
    <el-config-provider :locale="locale">
      <router-view v-if="isRouterAlive"/>
    </el-config-provider>
  </div>
</template>

<script lang="ts">
import ja from 'element-plus/es/locale/lang/ja'
import { ElConfigProvider } from 'element-plus'
import { defineComponent, getCurrentInstance, nextTick, onMounted, provide, ref, computed } from 'vue'

export default defineComponent({
  name: 'App',
  setup() {
    const isRouterAlive = ref(true)
    const isEditingValue = ref(false)
    const globalProperties = getCurrentInstance()?.appContext.config.globalProperties
    const reload = () => {
      try {
        isRouterAlive.value = false
        nextTick(() => {
          isRouterAlive.value = true
        })
      } catch (error) {
      }
    }

    // テーブルの値を編集中の状態の時、App内のクリックイベントを無効化する
    const rootClass = computed(() => {
      return isEditingValue.value ? 'disable-pointer-events' : ''
    })

    onMounted(async () => {
      if (globalProperties?.$getConfig) {
        await globalProperties.$getConfig()
      }
    })

    provide('reload', reload)
    provide('isEditingValue', isEditingValue)

    return {
      rootClass,
      locale: ja,
      isRouterAlive
    }
  }
})
</script>
