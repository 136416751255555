import { Utils } from '@/utils'
import { hasPermission } from '@/utils/permission'
import { getCurrentEdgeboxRequestIds } from '@/api/device/edgebox'

const log = {
  state: {
    logTime: 0,
    logDownloadList: [], // [{edgeBoxId: 'xxx', edgeBoxName: 'xxx', requestKind: 'x', requestId: 'xxx', startTime: xxx}]
    logIntervalId: null
  },

  mutations: {
    UPDATE_LOG_TIME: (state, date) => {
      state.logTime = date
    },
    CLEAR_LOG_INTERVAL_ID: (state) => {
      state.logIntervalId = null
    },
    SET_LOG_INTERVAL_ID: (state, intervalId) => {
      state.logIntervalId = intervalId
    },
    CLEAR_LOG: () => {
      const logNew = {
        logTime: 0,
        logDownloadList: []
      }

      if (!Utils.getLocalStorage('log') || !Utils.getLocalStorage('log').logTime || !Utils.getLocalStorage('log').logDownloadList) {
        Utils.saveLocalStorage('log', logNew)
      }

      if (Utils.getLocalStorage('log')) {
        const logLocal = Utils.getLocalStorage('log')
        logLocal.logDownloadList = []
        Utils.saveLocalStorage('log', logLocal)
      }
    }
  },

  actions: {
    clearLogIntervalId({ commit }) {
      commit('CLEAR_LOG_INTERVAL_ID')
    },
    setLogIntervalId({ commit }, intervalId) {
      commit('SET_LOG_INTERVAL_ID', intervalId)
    },
    clearAllLogDownload({ commit }) {
      commit('CLEAR_LOG')
      commit('UPDATE_LOG_TIME', 0)
    },
    async resetLogDownload({ commit }) {
      commit('CLEAR_LOG')
      if (hasPermission(['AUTH_EDGEBOX_LOG_DOWNLOAD'])) {
        // TenantId、userIdに従って、現在のデータベースのリクエストを取得します
        await getCurrentEdgeboxRequestIds().then(res => {
          const requestIds = res.value.requestIds
          if (requestIds && requestIds.length > 0) {
            commit('UPDATE_LOG_TIME', new Date().getTime())
            Utils.updateLogDownloadList(requestIds)
          } else {
            const logIntervalId = this.$store.getters.logIntervalId
            clearInterval(logIntervalId)
            this.$store.dispatch('clearLogIntervalId')
          }
        }).catch((e) => {
        }).finally(() => {
        })
      }
    }
  }
}

export default log
