import { defineComponent, onMounted, onBeforeUnmount, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
const WIDTH = 1024
const RATIO = 3

export function useResizeHandler() {
  const store = useStore()
  const route = useRoute()

  onMounted(() => {
    window.addEventListener('resize', resizeHandler)
    if (isMobile()) {
      store.dispatch('toggleDevice', 'mobile')
      store.dispatch('closeSideBar', { withoutAnimation: true })
    }
  })

  onBeforeUnmount(() => {
    window.removeEventListener('resize', resizeHandler)
  })

  const isMobile = () => {
    const rect = document.body.getBoundingClientRect()
    return rect.width - RATIO < WIDTH
  }

  const resizeHandler = () => {
    if (!document.hidden) {
      const isMobileResult = isMobile()
      store.dispatch('toggleDevice', isMobileResult ? 'mobile' : 'desktop')

      if (isMobileResult) {
        store.dispatch('closeSideBar', { withoutAnimation: true })
      }
    }
  }

  watch(route, () => {
    const device = store.getters.device
    const sidebarOpened = store.getters.sidebar.opened
    if (device === 'mobile' && sidebarOpened) {
      store.dispatch('closeSideBar', { withoutAnimation: false })
    }
  })
}
