import LayoutNoMenu from '../../../layout/LayoutNoMenu.vue'

const tenantCreate = [
  {
    path: '/tenant/create',
    name: 'Tenant_Create',
    component: () => import('@/views/tenant/edit/tenant_create.vue'),
    hidden: true,
    meta: {
      title: 'テナント新規登録',
      roles: ['SB_ADMIN'],
      authorities: ['AUTH_TENANT_CREATE']
    }
  }
]

const tenantCreateRouter = {
  path: '/tenant',
  component: LayoutNoMenu,
  name: 'TenantCreate',
  meta: { title: 'テナント新規登録' },
  hidden: true,
  children: [
    ...tenantCreate
  ]
}

export default tenantCreateRouter
