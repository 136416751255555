import LoadingButton from './LoadingButton.vue'
import MultiPanel from './MultiPanel.vue'
import BackLink from './BackLink.vue'
import DevicePagination from './DevicePagination.vue'
import SearchHeader from './SearchHeader.vue'
import FileUpload from './FileUpload.vue'
import DragDialog from './DragDialog.vue'
import PaginationTable from './PaginationTable.vue'
import TokenValid from './TokenValid.vue'
import CoordsCard from './CoordsCard.vue'
import FullScreenModal from './FullScreenModal.vue'
import InformationBar from './InformationBar.vue'
import EditableCell from './EditableCell.vue'

export function setupGlobalComponents(app) {
  app.component('LoadingButton', LoadingButton)
  app.component('MultiPanel', MultiPanel)
  app.component('BackLink', BackLink)
  app.component('DevicePagination', DevicePagination)
  app.component('SearchHeader', SearchHeader)
  app.component('FileUpload', FileUpload)
  app.component('DragDialog', DragDialog)
  app.component('PaginationTable', PaginationTable)
  app.component('TokenValid', TokenValid)
  app.component('CoordsCard', CoordsCard)
  app.component('FullScreenModal', FullScreenModal)
  app.component('InformationBar', InformationBar) 
  app.component('EditableCell', EditableCell)
}
