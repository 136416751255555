<template>
  <el-footer class="el-footer-layout" height="50px"><b>{{ footerContent }}</b></el-footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'AppFooter',
  props: {
    footerContent: {
      type: String,
      default: function() {
        return '© SoftBank Corp. All Rights Reserved. '
      }
    }
  }
})
</script>

<style>
 .el-footer-layout {
   text-align: center;
   vertical-align: center;
   line-height: 50px;
   position: relative;
   overflow: hidden;
   color: darkgrey;
   font-size: small;
  }
</style>
