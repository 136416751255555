import permission from './permission/permission' // permission control
import { hasPermission, isSbAccount, isSbAdmin, isSbOpr, isUserAdmin, isUserOpr } from '@/utils/permission'
import { isMasterAiappPage, getAiAppMode } from '@/utils/aiapp'
import DragDialog from './el-drag-dialog/index'
import scroll from './scroll/index'
import VueClipboard from 'vue-clipboard2'

export function setDirective(app) {
  app.directive('permission', permission)
  app.config.globalProperties.hasPermission = hasPermission
  app.config.globalProperties.isSbAccount = isSbAccount
  app.config.globalProperties.isSbAdmin = isSbAdmin
  app.config.globalProperties.isSbOpr = isSbOpr
  app.config.globalProperties.isUserAdmin = isUserAdmin
  app.config.globalProperties.isUserOpr = isUserOpr
  app.config.globalProperties.isMasterAiappPage = isMasterAiappPage
  app.config.globalProperties.getAiAppMode = getAiAppMode
  
  app.directive('scroll-update', scroll)

  VueClipboard.install(app)
  DragDialog.install(app)
}
