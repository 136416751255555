import LayoutNoMenu from '../../../layout/LayoutNoMenu.vue'

const sbAccount = [
  {
    path: '/account/list',
    name: 'SB_Account_Main',
    component: () => import('@/views/account/sb/main/sb_account_main.vue'),
    hidden: true,
    meta: { title: 'SBユーザー管理', svg: 'system', roles: ['SB_ADMIN', 'SB_OPR'], authorities: ['AUTH_SB_ACCOUNT_LIST'] }
  },
  {
    path: '/account/batch/create',
    name: 'SB_Account_Batch_Create',
    component: () => import('@/views/account/sb/edit/sb_account_csv_batch_create.vue'),
    hidden: true,
    meta: {
      title: 'SBユーザー一括登録',
      activeMenu: { path: '/account/list', title: 'SBユーザー管理' },
      roles: ['SB_ADMIN', 'SB_OPR'],
      authorities: ['AUTH_SB_ACCOUNT_UPLOAD']
    }
  },
  {
    path: '/account/create',
    name: 'SB_Account_Create',
    component: () => import('@/views/account/sb/edit/sb_account_create.vue'),
    hidden: true,
    meta: {
      title: 'SBユーザー新規登録',
      activeMenu: { path: '/account/list', title: 'SBユーザー管理' },
      roles: ['SB_ADMIN', 'SB_OPR'],
      authorities: ['AUTH_SB_ACCOUNT_CREATE']
    }
  },
  {
    path: '/account/show',
    name: 'SB_Account_Show',
    component: () => import('@/views/account/sb/detail/sb_account_show.vue'),
    hidden: true,
    meta: {
      title: 'SBユーザー詳細情報',
      activeMenu: { path: '/account/list', title: 'SBユーザー管理' },
      roles: ['SB_ADMIN', 'SB_OPR'],
      authorities: ['AUTH_SB_ACCOUNT_READ']
    }
  },
  {
    path: '/account/edit',
    name: 'SB_Account_Edit',
    component: () => import('@/views/account/sb/edit/sb_account_edit.vue'),
    hidden: true,
    meta: {
      title: 'SBユーザー情報編集',
      activeMenu: { path: '/account/list', title: 'SBユーザー管理' },
      roles: ['SB_ADMIN', 'SB_OPR'],
      authorities: ['AUTH_SB_ACCOUNT_UPDATE']
    }
  },
  {
    path: '/account/show/self',
    name: 'SB_Account_Show_Self',
    component: () => import('@/views/account/sb/detail/sb_account_show.vue'),
    hidden: true,
    meta: {
      title: 'SBユーザー詳細情報',
      activeMenu: { path: '/account/list', title: 'SBユーザー管理' },
      roles: ['SB_ADMIN', 'SB_OPR'],
      authorities: ['AUTH_SB_ACCOUNT_READ']
    }
  },
  {
    path: '/account/edit/self',
    name: 'SB_Account_Edit_Self',
    component: () => import('@/views/account/sb/edit/sb_account_edit.vue'),
    hidden: true,
    meta: {
      title: 'SBユーザー情報編集',
      activeMenu: { path: '/account/list', title: 'SBユーザー管理' },
      roles: ['SB_ADMIN', 'SB_OPR'],
      authorities: ['AUTH_SB_ACCOUNT_UPDATE']
    }
  }
]

const accountRouter = {
  path: '/account',
  component: LayoutNoMenu,
  name: 'Account',
  meta: { title: 'SBユーザー管理', svg: 'system' },
  children: [
    ...sbAccount
  ]
}

export default accountRouter
