export default {
  bind(el, binding) {
    if (binding.arg === 'handle') {
      el.handleScrollUpdate = binding.value
    } else {
      el.handleScrollUpdate = () => {}
    }
    el.addEventListener('scroll', el.handleScrollUpdate)
  },
  unmounted(el) {
    el.removeEventListener('scroll', el.handleScrollUpdate)
  }
}
