<template>
  <el-dialog
    :key="dialogKey"
    :model-value="dialogVisible"
    :append-to-body="appendToBody"
    :close-on-click-modal="false"
    :before-close="handleClose"
    :title="title"
    :class="'dialog__width_' + width"
    :is-close-button-only="isCloseButtonOnly"
    @keydown.shift.tab="returnLastFocus">
    <slot/>
    <template #footer>
      <span v-if="buttonBarShow && !isDeleteDialog" class="dialog-footer">
        <el-button v-if="cancelButtonShow" size="default" class="secondary-el-button" @click="handleClose()">キャンセル</el-button>
        <el-button :id="okButtonId" :loading="buttonLoading" size="default" @keydown.tab="returnFirstFocusInButton" @click="handleOk()">{{ okButtonText }}</el-button>
      </span>
      <span v-if="buttonBarShow && isDeleteDialog" class="dialog-footer">
        <el-button v-if="cancelButtonShow" size="default" @click="handleClose()">キャンセル</el-button>
        <el-button :id="okButtonId" :loading="buttonLoading" size="default" class="secondary-el-button" @keydown.tab="returnFirstFocusInButton" @click="handleOk()">{{ okButtonText }}</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import { defineComponent, nextTick, ref } from 'vue'

export default defineComponent({
  name: 'DragDialog',
  props: {
    title: {
      type: String,
      required: true
    },
    appendToBody: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: "900"
    },
    okButtonText: {
      type: String,
      default: '確定'
    },
    cancelButtonShow: {
      type: Boolean,
      default: true
    },
    buttonLoading: {
      type: Boolean,
      default: false
    },
    buttonBarShow: {
      type: Boolean,
      default: true
    },
    isDeleteDialog: {
      type: Boolean,
      default: false
    },
    // shift+tab用
    okButtonId: {
      type: String,
      default: ''
    },
    // shift+tab用
    isCloseButtonOnly: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const dialogKey = ref<number>(0)
    const dialogVisible = ref<boolean>(false)
    let callback: (close: () => void) => void = () => {}

    const open = (callbackFunction: (close: () => void) => void) => {
      callback = callbackFunction || ((close) => { close() })
      dialogVisible.value = true
      returnFirstFocus()
    }

    const returnFirstFocus = () => {
      const elements = document.getElementsByClassName('el-dialog__headerbtn')
      Array.prototype.forEach.call(elements, function (e: HTMLElement) {
        if (e && e.offsetParent !== null) {
          e.focus()
        }
      })
      setTimeout(() => {
        Array.prototype.forEach.call(elements, function (e: HTMLElement) {
          if (e && e.offsetParent !== null) {
            e.focus()
          }
        })
      }, 100)
    }

    // tab押下のfocus処理
    const returnFirstFocusInButton = (e: KeyboardEvent) => {
      // shift+tab押下の場合、return
      if (e.shiftKey) {
        return
      }
      const elements = document.getElementsByClassName('el-dialog__headerbtn')
      setTimeout(() => {
        Array.prototype.forEach.call(elements, function (e: HTMLElement) {
          if (e && e.offsetParent !== null) {
            e.focus()
          }
        })
      }, 100)
    }

    const returnLastFocus = () => {
      // shift+tab押下のfocus処理
      const activeElement = document.activeElement
      const okButtonId = props.okButtonId
      const isCloseButtonOnly = props.isCloseButtonOnly
      const elements = document.getElementsByClassName('el-dialog__headerbtn')
      if (isCloseButtonOnly) {
        return
      }
      if (okButtonId === '') {
        return
      }
      if (activeElement.className === 'el-dialog__headerbtn') {
        setTimeout(() => {
          Array.prototype.forEach.call(elements, function (e: HTMLElement) {
            if (e && e.offsetParent !== null) {
              if (okButtonId === 'tenantInputBox') { // tenant選択ダイアログにボタンがない場合
                document.getElementById(okButtonId).getElementsByTagName('input')[0].focus()
              } else {
                document.getElementById(okButtonId).focus()
              }
            }
          })
        }, 100)
      }
    }

    const handleClose = () => {
      dialogKey.value++
      handleResetFields()
      emit('beforeClose')
    }

    const handleResetFields = () => {
      nextTick().then(() => {
        emit('handleResetFields')
      }).then(() => {
        dialogVisible.value = false
      })
    }

    const handleOk = () => {
      callback(() => {
        handleResetFields()
      })
    }

    return {
      dialogKey,
      dialogVisible,
      open,
      returnFirstFocusInButton,
      returnLastFocus,
      handleClose,
      handleOk
    }
  }
})
</script>
