import LayoutNoAuth from '../../../layout/LayoutNoAuth.vue'

const passwordRouter = {
  path: '/password',
  component: LayoutNoAuth,
  name: 'password',
  hidden: true,
  children: [
    {
      path: 'sendEmail',
      name: 'password_send_email',
      component: () => import('@/views/password/send/send_email.vue'),
      meta: { title: 'パスワードリセット' }
    },
    {
      path: 'noticeSuccess',
      name: 'password_notice_success',
      component: () => import('@/views/password/send/notice_success.vue'),
      meta: { title: 'パスワードリセットのメール送信完了' }
    },
    {
      path: 'set',
      name: 'password_setting',
      component: () => import('@/views/password/reset/reset.vue'),
      meta: { title: 'パスワード設定' }
    },
    {
      path: 'reset',
      name: 'password_resetting',
      component: () => import('@/views/password/reset/reset.vue'),
      meta: { title: 'パスワードリセット' }
    },
    {
      path: 'setSuccess',
      name: 'password_set_success',
      component: () => import('@/views/password/reset/reset_success.vue'),
      meta: { title: 'パスワード設定完了' }
    },
    {
      path: 'resetSuccess',
      name: 'password_reset_success',
      component: () => import('@/views/password/reset/reset_success.vue'),
      meta: { title: 'パスワードリセット完了' }
    }
  ]
}

export default passwordRouter
