const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  userId: state => state.auth.user.userId,
  userName: state => state.auth.user.userName,
  userEmail: state => state.auth.user.userEmail,
  lastLoginAt: state => state.auth.user.lastLoginAt,
  tenantId: state => state.auth.user.tenantId,
  tenantName: state => state.auth.user.tenantName,
  roleId: state => state.auth.roleId,
  authorities: state => state.auth.authorities,
  logIntervalId: state => state.log.logIntervalId,
  videoIntervalId: state => state.detection.videoIntervalId,
  logDownloadList: state => state.logDownloadList
}
export default getters
