import Layout from '../../../layout/Layout.vue'

const video = [
  {
    path: '/analysis/video/list',
    name: 'Analysis_VideoReceive',
    component: () => import('@/views/video/main/video_main.vue'),
    meta: {
      title: '映像・画像',
      icon: 'VideoPlay',
      roles: ['USER_ADMIN', 'USER_OPR'],
      authorities: ['AUTH_CAMERA_VIDEO']
    }
  },
  {
    path: '/analysis/video/detail',
    name: 'Analysis_Video_Detail',
    hidden: true,
    component: () => import('@/views/video/detail/video_detail.vue'),
    meta: {
      title: '映像・画像一覧',
      activeMenu: { path: '/analysis/video/list', title: '映像' },
      roles: ['USER_ADMIN', 'USER_OPR'], authorities: ['AUTH_CAMERA_VIDEO']
    }
  }
]

const analysisRouter = {
  path: '/analysis',
  component: Layout,
  name: 'Analysis',
  meta: { title: '映像解析', svg: 'system', roles: ['USER_ADMIN', 'USER_OPR'] },
  children: [
    ...video
  ]
}

export default analysisRouter
