<template>
  <!-- 以降、Element内部クラスのoverrideはinline styleでしか効かないためタグ内に記載 -->
  <div :style="{'width': (device === 'mobile' ? '100%': '')}" class="navbar-container">
    <div class="navbar-header">
      <div class="logo-bar">
        <h1 class="sidebar-title">{{ isSbAccount() ? '映像解析PF Console' : '映像解析PF' }}</h1>
      </div>
      <router-link to="" @click="toTenantList">
        <div v-if="isSbAccount()" id="tenantMenu" :class="{'active': isActive('tenant')}" class="menu-item">
          テナント管理
        </div>
      </router-link>
      <router-link to="" @click="toSBUserList">
        <div v-if="isSbAccount()" id="sbAccountMenu" :class="{'active': isActive('account')}" class="menu-item">
          SBユーザー管理
        </div>
      </router-link>
      <div v-if="isSbAccount()" class="logo-item">
        <el-dropdown trigger="hover" placement="bottom-start">
          <div id="deviceMaster" :class="{'active': isActive('deviceMaster')}" class="menu-item">
            <span class="el-dropdown-link">デバイスマスタ管理</span>
          </div>
          <template v-slot:dropdown>
            <el-dropdown-menu style="padding: 12px 8px;">
              <a class="inlineBlock" @click.native="toManagementModule">
                <el-dropdown-item
                  id="managementModule"
                  :class="{'bold': isBold('managementModule')}"
                  class="dropdown-item-management-module"
                  style="color: #333333 !important; font-size: 14px;">
                  管理モジュール
                </el-dropdown-item>
              </a>
              <a class="inlineBlock" @click.native="toEdgeBoxKind">
                <el-dropdown-item
                  id="edgeBoxKind"
                  :class="{'bold': isBold('edgeboxKind')}"
                  class="dropdown-item-edgebox-kind"
                  style="color: #333333 !important; font-size: 14px; margin-top: 8px;">
                  エッジボックスモデル
                </el-dropdown-item>
              </a>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <router-link to="" @click="toAiAppMaster">
        <div v-if="isSbAccount()" id="aiAppMaster" :class="{'active': isActive('aiAppMaster')}" class="menu-item">
          AIアプリマスタ管理
        </div>
      </router-link>
      <router-link to="" @click="toHelpList">
        <div v-if="isSbAccount()" id="helpMenu" :class="{'active': isActive('help')}" class="menu-item">
          ヘルプ管理
        </div>
      </router-link>
      <router-link to="" @click="toSettlementList">
        <div v-if="isSbAccount()" id="settlementMenu" :class="{'active': isActive('settlement')}" class="menu-item">
          事業者間精算
        </div>
      </router-link>
      <el-dropdown class="avatar-container" trigger="hover">
        <div class="avatar-wrapper">
          <span><svg-icon icon-class="user" class-name="icon-header" class="user-icon"/></span>
        </div>
        <template #dropdown>
          <el-dropdown-menu id="icon" class="user-dropdown" style="
            border-radius: 0% !important;
            border: 1px solid #333333 !important;
            box-shadow: none !important;
            ">
            <el-dropdown-item disabled style="
              padding: 14px 24px !important;
              font-size: 14px !important;
              color: #333333 !important;
              ">
              <div >{{ userName }}</div>
              <div class="dropdown-item-user" style="margin: 0 32px 16px 0;">{{ userEmail }}</div>
              <el-divider/>
              <div id="loginTime" style="font-size: 12px !important; margin-top: 12px;">
                前回ログイン日時：
                <div class="dropdown-item-user">{{ lastLoginAt }}</div>
              </div>
            </el-dropdown-item>
            <a class="inlineBlock" @click="about">
              <el-dropdown-item id="account" style="
                padding: 14px 24px !important;
                font-size: 14px !important;
                color: #333333 !important;
                border-top: 1px solid #333333;
                border-bottom: 1px solid #333333;
                ">
                アカウント設定
              </el-dropdown-item>
            </a>
            <a class="inlineBlock" @click="logout">
              <el-dropdown-item id="logout" style="
                padding: 14px 24px !important;
                font-size: 14px !important;
                color: #333333 !important;
                ">
                ログアウト
              </el-dropdown-item>
            </a>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <el-dropdown v-if="!isSbAccount()" class="avatar-container">
        <div id="helpIcon" class="avatar-wrapper">
          <span><svg-icon icon-class="help" class-name="icon-header" @click="toHelpMain"/></span>
        </div>
      </el-dropdown>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from 'vuex'
import { isSbAccount } from '@/utils/permission'
import { computed, defineComponent, getCurrentInstance, onBeforeUnmount, onMounted, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ElMessageBox } from 'element-plus'

export default defineComponent({
  props: {
    hasMenu: {
      type: Boolean,
      default: () => true
    }
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const sidebar = computed(() => store.getters.sidebar)
    const device = computed(() => store.getters.device)
    const userEmail = computed(() => store.getters.userEmail)
    const userName = computed(() => store.getters.userName)
    const lastLoginAt = computed(() => store.getters.lastLoginAt)
    const instance = getCurrentInstance()
    const isSbAccount = () => {
      return instance.appContext.config.globalProperties.isSbAccount()
    }
    const state = reactive({
      ptShow: '',
      user: {
        accountId: store.getters.userId,
        accountName: store.getters.userName
      },
      isFullscreen: false
    })

    onMounted(() => {
      init()
    })

    onBeforeUnmount(() => {
      destroy()
    })

    const logout = () => {
      ElMessageBox.confirm('ログアウトします。よろしいですか？', 'ログアウト', {
        confirmButtonText: 'ログアウトする',
        cancelButtonText: 'キャンセル',
        confirmButtonClass: 'el-button--medium el-message-box-confirm-btn',
        cancelButtonClass: 'el-button--medium secondary-el-button el-message-box-cancel-btn',
        closeOnClickModal: false,
        customClass: 'logout-confirm-box',
        type: ''
      }).then(() => {
        store.dispatch('logOut').then(() => {
          location.reload() // Vue-routerオブジェクトを再実装するためにバグを避ける
        })
      }).catch(() => {
      }).finally(() => {
      })
    }
    const about = () => {
      if (isSbAccount()) {
        router.push({ path: `/account/show/self` })
      } else {
        router.push({ path: `/tenant/account/show/self` })
      }
    }
    const toTenantList = () => {
      router.push({ path: `/tenant/list` })
    }
    const toSBUserList = () => {
      router.push({ path: `/account/list` })
    }
    const toHelpList = () => {
      router.push({ path: `/help/list` })
    }
    const toSettlementList = () => {
      router.push({ path: `/intercompany-settlement/list` })
    }
    const toManagementModule = () => {
      router.push({ path: `/device/master/management-module/list` })
    }
    const toAiAppMaster = () => {
      router.push({ path: `/master/algorithm/list` })
    }
    const toEdgeBoxKind = () => {
      router.push({ path: `/device/master/edgebox-kind/list` })
    }
    const isActive = (biz) => {
      if (biz === 'account') {
        return route.fullPath.startsWith('/account/')
      } else if (biz === 'deviceMaster') {
        return route.fullPath.startsWith('/device/master/')
      } else if (biz === 'aiAppMaster') {
        return route.fullPath.startsWith('/master/algorithm')
      } else if (biz === 'help') {
        return route.fullPath.startsWith('/help/')
      } else if (biz === 'settlement') {
        return route.fullPath.startsWith('/intercompany-settlement/')
      } else if (biz === 'tenant') {
        return !route.fullPath.startsWith('/account/') &&
          !route.fullPath.startsWith('/help/') &&
          !route.fullPath.startsWith('/notice/') &&
          !route.fullPath.startsWith('/device/master/') &&
          !route.fullPath.startsWith('/intercompany-settlement/') && 
          !route.fullPath.startsWith('/master/algorithm')
      }
      return false
    }

    const isBold = (biz: string) => {
      if (biz === 'managementModule') {
        return route.fullPath.startsWith('/device/master/management-module/')
      } else if (biz === 'edgeboxKind') {
        return route.fullPath.startsWith('/device/master/edgebox-kind/')
      }
    }
    const toHelpMain = () => {
      router.push({ path: `/help/list` })
    }
    const change = () => {
      state.isFullscreen = instance.appContext.config.globalProperties.$screenfull.isFullscreen
    }
    const init = () => {
      if (instance.appContext.config.globalProperties.$screenfull.isEnabled) {
        instance.appContext.config.globalProperties.$screenfull.on('change', change)
      }
      if (!store.getters.userEmail) {
        store.dispatch('refreshAuth').then(() => {
        }).catch(e => {
          console.error(e)
        })
      }
    }
    const destroy = () => {
      if (instance.appContext.config.globalProperties.$screenfull.isEnabled) {
        instance.appContext.config.globalProperties.$screenfull.off('change', change)
      }
    }

    return {
      state,
      isSbAccount,
      sidebar,
      device,
      userEmail,
      userName,
      lastLoginAt,
      logout,
      about,
      toTenantList,
      toSBUserList,
      toHelpList,
      toSettlementList,
      toManagementModule,
      toAiAppMaster,
      toEdgeBoxKind,
      isActive,
      isBold,
      toHelpMain

    }
  }
})
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  $headerNaviBgColor: #0C2533;

  .bold {
    font-weight: bold;
  }
  .navbar-container {
    display: block;
    height: 50px;
    line-height: 50px;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: 100%;

    .navbar-header {
      height: 50px;
      line-height: 50px;
      background: $headerNaviBgColor;
      box-shadow: 0 1px 4px rgba(0, 21, 41, .08);

      display: block;
      .logo-bar {
        line-height: 50px;
        height: 50px;
        float: left;
        width: 220px;
        & .sidebar-title {
          display: inline-block;
          margin: 0 20px;
          color: #fff;
          font-weight: 600;
          line-height: 50px;
          font-size: 16px;
          vertical-align: middle;
        }
      }
      .logo-item {
        display: inline-block;
        line-height: 30px;
        height: 30px;
        float: left;
        width: 140px;
        text-align: center;
        color: #fff;
        margin-left: 0px;
        margin-right: 14px;
        font-size: 14px;
      }

      .menu-item {
        display: inline-block;
        line-height: 30px;
        height: 30px;
        margin-top: 10px;
        float: left;
        width: 140px;
        text-align: center;
        color: #fff;
        margin-left: 14px;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        &:hover {
          background-color: #44535B;
          border-radius: 2px;
        }
      }

      .menu-item.active {
        background-color: #fff;
        border-radius: 2px;
        color: $headerNaviBgColor;
        font-weight: 700;
      }

      .icon-profile::before {
        content: "\e97e";
      }
      .el-dropdown {
        color: #FFF;
      }
      .avatar-container {
        height: 50px;
        float: right;
        margin-right: 20px;
        display: inline-block;

        .icon-header {
          width: 1.5em;
          height: 1.5em;
          vertical-align: -0.5em;
        }

        .avatar-wrapper {
          cursor: pointer;
          line-height: 50px;
          position: relative;
        }
      }
    }

    .inlineBlock {
      margin-top: 15px;
    }
    .user-icon {
      margin-right: 5px;
    }
    .dropdown-item-user {
      margin-top: -24px !important;
    }
  }
</style>

