import drag from './drag'

const install = function(app) {
  app.directive('drag-dialog', drag)
}

if (window.Vue) {
  window['drag-dialog'] = drag
  app.use(install); // eslint-disable-line
}

drag.install = install
export default drag
