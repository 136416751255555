<template>
  <div v-if="!item.hidden" class="menu-wrapper">
    <SidebarMenuLink :to="resolvePath(item.path)" :target="item.target || ''">
      <el-menu-item :index="resolvePath(item.path)" :class="{'submenu-title-noDropdown':!isNest}">
        <SidebarMenuItem
          v-if="item.meta && item.meta.icon"
          :icon="(item.meta.icon as string)"
          :title="(item.meta.title as string)"/>
        <SidebarMenuItem
          v-else-if="item.meta && item.meta.svg"
          :svg="(item.meta.svg as string)"
          :title="(item.meta.title as string)" />
      </el-menu-item>
    </SidebarMenuLink>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, PropType } from 'vue'
import SidebarMenuItem from './SidebarMenuItem.vue'
import SidebarMenuLink from './SidebarMenuLink.vue'
import { CustomRouteRecordRaw } from './Sidebar.vue'

export default defineComponent({
  components: { SidebarMenuItem, SidebarMenuLink },
  props: {
    // route object
    item: {
      type: Object as PropType<CustomRouteRecordRaw>,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const onlyOneChild = ref<CustomRouteRecordRaw | null>(null)

    const hasOneShowingChild = (children: CustomRouteRecordRaw[], parent: CustomRouteRecordRaw) => {
      const showingChildren = children.filter(item => {
        if (props.item.hidden) {
          return false
        } else {
          // Temp set(will be used if only has one showing child)
          onlyOneChild.value = item
          return true
        }
      })

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        onlyOneChild.value = { ...parent, path: '', noShowingChildren: true }
        return true
      }

      return false
    }

    const resolvePath = (routePath: string) => {
      if (isExternal(routePath)) {
        return routePath
      }
      return routePath
    }

    const isExternal = (path: string) => {
      return /^(https?:|mailto:|tel:)/.test(path)
    }

    return {
      hasOneShowingChild,
      resolvePath
    }
  }
})
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .el-menu-item {
    height: 42px !important;
    line-height: 42px !important;
    font-size: 16px !important;
  }
  .el-menu-item i {
    color: #333333;
  }
  .el-menu-item.is-active i {
    color: #333333;
  }
  .el-menu-item.is-active {
    color: #333333;
    font-weight: 700;
    border-right: 4px solid #007bc2;
  }
</style>
