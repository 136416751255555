import LayoutNoMenu from '../../../layout/LayoutNoMenu.vue'

const settlement = [
  {
    path: '/intercompany-settlement/list',
    name: 'Settlement_Main',
    component: () => import('@/views/settlement/main/settlement_main.vue'),
    hidden: true,
    meta: { title: '事業者間精算', roles: ['SB_ADMIN', 'SB_OPR'], authorities: ['AUTH_SETTLEMENT_DOWNLOAD'] }
  }
]

const settlementRouter = {
  path: '/intercompany-settlement',
  component: LayoutNoMenu,
  name: 'Settlement',
  meta: { title: '事業者間精算', svg: 'system', authorities: ['AUTH_SETTLEMENT_DOWNLOAD'] },
  children: [
    ...settlement
  ]
}

export default settlementRouter
