import LayoutNoMenu from '../../../layout/LayoutNoMenu.vue'

const sbHelp = [
  {
    path: '/help/list',
    name: 'Help_Main',
    component: () => import('@/views/help/main/help_main.vue'),
    hidden: true,
    meta: { title: 'ヘルプ管理', svg: 'system', roles: ['SB_ADMIN', 'SB_OPR'], authorities: ['AUTH_HELP_LIST'] }
  },
  {
    path: '/help/show',
    name: 'Help_Show',
    hidden: true,
    component: () => import('@/views/help/detail/help_show.vue'),
    meta: {
      title: ' ヘルプ詳細',
      activeMenu: { path: '/help/list', title: 'ヘルプ管理' },
      roles: ['SB_ADMIN', 'SB_OPR'],
      authorities: ['AUTH_HELP_READ']
    }
  },
  {
    path: '/help/create',
    name: 'Help_Create',
    component: () => import('@/views/help/edit/help_create.vue'),
    hidden: true,
    meta: {
      title: 'ヘルプ新規登録',
      activeMenu: { path: '/help/list', title: 'ヘルプ管理' },
      roles: ['SB_ADMIN'],
      authorities: ['AUTH_HELP_CREATE']
    }
  },
  {
    path: '/help/edit',
    name: 'Help_Edit',
    component: () => import('@/views/help/edit/help_edit.vue'),
    hidden: true,
    meta: {
      title: 'ヘルプ編集',
      activeMenu: { path: '/help/list', title: 'ヘルプ管理' },
      roles: ['SB_ADMIN'],
      authorities: ['AUTH_HELP_UPDATE']
    }
  }
]

const sbHelpRouter = {
  path: '/sb/help',
  component: LayoutNoMenu,
  name: 'SbHelp',
  meta: { title: 'ヘルプ管理', svg: 'system' },
  children: [
    ...sbHelp
  ]
}

export default sbHelpRouter
