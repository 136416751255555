<template>
  <div class="pass_layout">
    <app-main/>
    <app-footer/>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import AppMain from './components/AppMain.vue'
import AppFooter from './components/AppFooter.vue'

export default defineComponent({
  name: 'LayoutNoAuth',
  components: {
    AppMain,
    AppFooter
  },
  setup() {
    const router = useRouter()
    const gotoLogin = () => {
      router.push('/login')
    }
    return {
      gotoLogin
    }
  }
})
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .pass_layout {
    background: #FDFDFD;

    .title {
      height: 50px;
      line-height: 50px;
      box-shadow: 0 1px 4px rgba(0, 21, 41, .08);
      background: #FDFDFD;
      position: fixed;
      top: 0;
      right: 0;
      z-index: 9;
      width: 100%;
    }

    .title-div {
      margin: 0 auto;
      border: 0px solid #000;
      width: 60%;
      min-width: 1024px;
      height: 50px
    }

    .title-button {
      display: block;
      float: right;
      height: 50px;
      margin: 0px 10px 0 2px;
      margin-top:-50px;
      width:23%;
      font-size: 14px;
      color: #2c82ff;
    }
  }
</style>
