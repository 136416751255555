/**
 * validate.js
 */
export function isEmpty(str) {
  return str !== null && str.trim().length === 0
}

export function isNull(str) {
  return str === null
}

export function isValidEmailCharacter(str) {
  return /^[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~()<>\[\]:;@\\,."]*$/.test(str)
}
export function isValidEmailStyle(str) {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(str)
}

export function isValidInteger(str) {
  return /^-?(0|[1-9][0-9]*)$/.test(str)
}

export function isValidLocalLength(str, character, length) {
  return str.substr(0, str.indexOf(character)).length <= length
}

export function isValidIntegerAndLetter(str) {
  return /^[A-Za-z0-9_\-\.]+$/.test(str)
}

export function isValidTenantCreate(str) {
  return /^[A-Za-z0-9]*$/.test(str)
}

export function isValidUrl(str) {
  return /^https?:\/{2}[\w\/:%#\$&\?\(\)~\.=\+\-]+$/.test(str) || /^http?:\/{2}[\w\/:%#\$&\?\(\)~\.=\+\-]+$/.test(str)
}

export function isValidFileName(str) {
  return /^[^#%?\\]+$/.test(str)
}

export function isValidEncodeFileName(str) {
  return /^[\da-zA-Z+_)(&$@!~=\-;',.]+$/.test(str)
}
