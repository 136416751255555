<script lang="ts">
import { ElIcon } from 'element-plus'
import { defineComponent, h, ref, computed, resolveComponent } from 'vue'

export default defineComponent({
  props: {
    svg: String,
    icon: String,
    title: String,
  },
  setup(props) {
    const svg = ref(props.svg)
    const icon = ref(props.icon)
    const title = ref(props.title)

    const vnodes = computed(() => {
      const vnodes = []

      if (svg.value) {
        const SvgIconComponent = resolveComponent('svg-icon')
        vnodes.push(h(SvgIconComponent, {
          iconClass: svg.value,
          style: 'margin: 0px 9px 0px 5px !important' 
        }))
      }

      if (icon.value) {
        const IconComponent = resolveComponent(icon.value)
        vnodes.push(h(ElIcon, () => h(IconComponent)))
      }

      if (title.value) {
        vnodes.push(h('span', { slot: 'title' }, title.value))
      }
      return vnodes
    })
    return () => vnodes.value
  }
})
</script>
