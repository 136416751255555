<template>
  <div :class="classObj" class="app-wrapper">
    <div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside"/>
    <navbar/>
    <div class="main-container">
      <sidebar class="sidebar-container"/>
      <app-main/>
      <app-footer/>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { Navbar, Sidebar, AppMain, AppFooter } from './components'
import { useResizeHandler } from './mixin/ResizeHandler'

export default defineComponent({
  name: 'Layout',
  components: {
    Navbar,
    Sidebar,
    AppMain,
    AppFooter
  },
  setup() {
    useResizeHandler()
    const store = useStore()

    const sidebar = computed(() => store.getters.sidebar)
    const device = computed(() => store.getters.device)
    const classObj = computed(() => {
      hideSidebar: !sidebar.value.opened
      openSidebar: sidebar.value.opened
      withoutAnimation: sidebar.value.withoutAnimation
      mobile: device.value === 'mobile'
    })
    const handleClickOutside = () => {
      store.dispatch('closeSideBar', { withoutAnimation: false })
    }
    return {
      sidebar,
      device,
      classObj,
      handleClickOutside
    }
  }
})
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  @import "@/assets/styles/mixin.scss";
  .app-wrapper {
    @include clearfix;
    position: relative;
    height: 100%;
    width: 100%;
    background-color: #fafafa !important;
    &.mobile.openSidebar{
      position: fixed;
      top: 0;
      background-color: #fafafa !important;
    }
  }
  .drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }
</style>
