<template>
  <div>
    <SidebarTenant/>
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :show-timeout="200"
        :default-active="activeMenu(route, route.path)"
        :collapse="isCollapse"
        :background-color="'var(--menu-bg)'"
        :text-color="'var(--menu-text)'"
        :unique-opened="false"
        :active-text-color="'var(--menu-active-text)'"
        mode="vertical"
      >
        <template v-for="route in routers">
          <el-menu-item-group v-if="route.hidden !== true" :key="route.path">
            <template v-if="route.name !== 'Analysis' && hasChildren(route) && (route.name !== 'Device' || !isSbAccount())" v-slot:title>
              <el-divider class="menu-split"/>
            </template>
            <SidebarMenu v-for="r in route.children" :item="r" :key="r.path" :base-path="r.path"/>
          </el-menu-item-group>
        </template>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, onMounted, ref, Ref, computed, watch
} from 'vue'
import { useRouter, RouteRecordRaw, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { isSbAccount } from '@/utils/permission.js'
import SidebarMenu from './SidebarMenu.vue'
import SidebarTenant from './SidebarTenant.vue'

export type CustomRouteRecordRaw = RouteRecordRaw & {
  hidden?: boolean
  target?: string
  noShowingChildren?: boolean
  icon?: string,
  svg?: string,
  title?: string
}
export default defineComponent({
  components: { SidebarMenu, SidebarTenant },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const routers: Ref<CustomRouteRecordRaw[]> = ref(router.options.routes.map(route => route as CustomRouteRecordRaw))

    const shouldSidebarRefresh = computed(() => store.getters.sidebar.shouldRefresh)
    const isCollapse = computed(() => !store.getters.sidebar.opened)

    const activeMenu = (router, path: string) => {
      if (router.meta as any && router.meta.activeMenu) {
        return (router.meta as any).activeMenu.path
      }
      return path
    }

    const hasChildren = (route) => {
      return route.children && route.children.filter(e => e.hidden != true).length > 0
    }

    const refreshMenu = () => {
      routers.value = router.options.routes.map(route => route as CustomRouteRecordRaw)
    }

    onMounted(() => {
      if (!sessionStorage.getItem('reloaded')) {
        sessionStorage.setItem('reloaded', 'true')
        window.location.reload()
      } else {
        sessionStorage.removeItem('reloaded')
      }
      watch(shouldSidebarRefresh, (newValue: boolean, oldValue: boolean) => {
        if (newValue !== oldValue) {
          refreshMenu()
        }
        refreshMenu()
      })
    })

    return {
      route,
      routers,
      isCollapse,
      isSbAccount,
      activeMenu,
      hasChildren
    }
  }
})
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .el-divider--horizontal {
    margin: 0 !important;
    width: 90%;
  }
  .el-menu-item-group__title {
    padding: 8px 0 8px 20px !important;
  }
</style>
