import { createRouter, createWebHistory } from 'vue-router'
import { event } from './router_event'
import { store } from '@/common/store'
import { initVideoInterval } from '@/common/detection'
import { initLogInterval } from '@/common/log'

// in development-env not use lazy-loading, because lazy-loading too many pages will cause webpack hot update too slow. so only in production use lazy-loading;
// detail: https://panjiachen.github.io/vue-element-admin-site/#/lazy-loading

/* Layout */
import Layout from '../layout/Layout.vue'

import passwordRouter from './modules/password'
import analysisRouter from './modules/video'
import deviceRouter from './modules/device'
import tenantRouter from './modules/tenant'
import tenantCreateRouter from './modules/tenantcreate/index'
import accountRouter from './modules/account'
import sbHelpRouter from './modules/help/sb'
import masterAlgorithmRouter from './modules/algorithmmaster'
import userHelpRouter from './modules/help/user'
import settlementRouter from './modules/settlement'
import tenantSwitchRouter from './modules/tenantswitch'
import deviceMasterRouter from './modules/devicemaster'
import { isSbAccount } from '@/utils/permission'
import { Utils } from '@/utils'

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    title: 'title'               the name show in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if false, the item will hidden in breadcrumb(default is true)
  }
**/
export const constantRouterMap = [
  { path: '/login', name: 'login', component: () => import('@/views/login/login.vue'), meta: { title: 'ログイン' }, hidden: true },
  { path: '/404', name: '404', component: () => import('@/views/404.vue'), meta: { title: '404' }, hidden: true },
  {
    path: '/',
    component: Layout,
    redirect: '/',
    name: 'Top',
    hidden: true
  },
  passwordRouter,
  analysisRouter,
  deviceRouter,
  tenantRouter,
  tenantCreateRouter,
  accountRouter,
  deviceMasterRouter,
  masterAlgorithmRouter,
  sbHelpRouter,
  userHelpRouter,
  tenantSwitchRouter,
  settlementRouter,
  { path: '/:pathMatch(.*)*', name: 'catchAll', redirect: '/404', hidden: true }
]

const filterRoutes = function (routes, roleId, authorities) {
  function hasPermission(route) {
    if (route.meta && route.meta.roles && route.meta.authorities) {
      return route.meta.roles.includes(roleId) && authorities.some(auth => route.meta.authorities.includes(auth))
    } else if (route.meta && route.meta.roles) {
      return route.meta.roles.includes(roleId)
    } else if (route.meta && route.meta.authorities) {
      return authorities.some(auth => route.meta.authorities.includes(auth))
    } else {
      return true
    }
  }
  function filter(children) {
    let num = 0
    if (children) {
      children.forEach(r => {
        if (r.hidden !== true) {
          num++
        }
      })
    }
    return num === 0
  }
  const res = []
  routes.forEach(route => {
    const tmp = { ...route }
    if (hasPermission(tmp)) {
      if (tmp.children) {
        tmp.children = filterRoutes(tmp.children, roleId, authorities)
      }
      if (filter(tmp.children)) {
        tmp.show = false
      }
      if (tmp.path === '/') {
        if (isSbAccount()) {
          tmp.redirect = '/tenant/list'
        } else {
          tmp.redirect = '/device/edgebox/list'
        }
      }
      res.push(tmp)
    }
  })
  return res
}

// IIFE(即時実行関数)だと実行結果がexportされてimportの時うまく動かない
export function createAppRouter() {
  return createRouter({
    history: createWebHistory(), // require service support
    scrollBehavior: () => ({ top: 0 }),
    routes: filterRoutes(constantRouterMap, store.getters.roleId, store.getters.authorities || [])
  })
}
export const router = createAppRouter()

export function resetRouter() {
  router.getRoutes().forEach(route => {
    router.removeRoute(route.name)
  })
  const accessRoutes = filterRoutes(constantRouterMap, store.getters.roleId, store.getters.authorities || [])
  accessRoutes.forEach(route => {
    router.addRoute(route)
  })
  store.dispatch('refreshSideBar')
}

event.on('resetRouter', resetRouter)
event.on('resetDetectionDownload', () => {
  store.dispatch('resetDetectionDownload').then(() => {
    const downloadList = Utils.getLocalStorage('detection').downloadList
    if (downloadList && downloadList.length > 0) {
      initVideoInterval()
    }
  })
})
event.on('clearAllDetectionDownload', () => {
  store.dispatch('clearAllDetectionDownload')
})
event.on('resetLogDownload', () => {
  store.dispatch('resetLogDownload').then(() => {
    const logDownloadList = Utils.getLocalStorage('log').logDownloadList
    if (logDownloadList && logDownloadList.length > 0) {
      initLogInterval()
    }
  })
})
event.on('clearAllLogDownload', () => {
  store.dispatch('clearAllLogDownload')
})
