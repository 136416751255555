import getters from './getters'
import createPersistedState from 'vuex-persistedstate'
import { createStore } from 'vuex'

const modulesFiles = import.meta.globEager('./modules/*.js')
const modules = Object.entries(modulesFiles).reduce((modules, [path, module]) => {
  const moduleName = path.match(/\.\/modules\/(.*)\.js$/)[1]
  modules[moduleName] = module.default
  return modules
}, {})

const storage = {
  getItem: (key) => window.sessionStorage.getItem(key),
  setItem: (key, value) => {
    const v = JSON.parse(value)
    if (v.auth) {
      const authorities = v.auth.authorities || []
      const roleId = v.auth.roleId || ''
      delete v.auth
      v.auth = {
        roleId: roleId,
        authorities: authorities
      }
    }
    window.sessionStorage.setItem(key, JSON.stringify(v))
  },
  removeItem: key => window.sessionStorage.removeItem(key)
}

export const store = createStore({
  modules,
  plugins: [createPersistedState({
    storage: storage
  })],
  getters
})
