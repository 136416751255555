import LayoutNoMenu from '../../../layout/LayoutNoMenu.vue'

const edgeboxKind = [
  {
    path: '/device/master/edgebox-kind/list',
    name: 'EdgeBox_Kind_List',
    hidden: true,
    component: () => import('@/views/devicemaster/edgeboxkind/main/edgebox_kind_main.vue'),
    meta: {
      title: 'エッジボックスモデル',
      roles: ['SB_ADMIN', 'SB_OPR'],
      authorities: ['AUTH_EDGEBOX_KIND_LIST']
    }
  },
  {
    path: '/device/master/edgebox-kind/show',
    name: 'EdgeBox_Kind_Show',
    hidden: true,
    component: () => import('@/views/devicemaster/edgeboxkind/detail/edgebox_kind_show.vue'),
    meta: {
      title: ' エッジボックスモデル詳細',
      activeMenu: { path: '/device/master/edgebox-kind/list', title: 'エッジボックスモデル' },
      roles: ['SB_ADMIN', 'SB_OPR'],
      authorities: ['AUTH_EDGEBOX_KIND_READ']
    }
  },
  {
    path: '/device/master/edgebox-kind/create',
    name: 'EdgeBox_Kind_Create',
    hidden: true,
    component: () => import('@/views/devicemaster/edgeboxkind/edit/edgebox_kind_create.vue'),
    meta: {
      title: ' エッジボックスモデル新規登録',
      activeMenu: { path: '/device/master/edgebox-kind/list', title: 'エッジボックスモデル' },
      roles: ['SB_ADMIN'],
      authorities: ['AUTH_EDGEBOX_KIND_CREATE']
    }
  },
  {
    path: '/device/master/edgebox-kind/edit',
    name: 'EdgeBox_Kind_Edit',
    component: () => import('@/views/devicemaster/edgeboxkind/edit/edgebox_kind_edit.vue'),
    hidden: true,
    meta: {
      title: 'エッジボックスモデル編集',
      activeMenu: { path: '/device/master/edgebox-kind/list', title: 'エッジボックスモデル' },
      roles: ['SB_ADMIN'],
      authorities: ['AUTH_EDGEBOX_KIND_UPDATE']
    }
  }
]

const ManagementModule = [
  {
    path: '/device/master/management-module/list',
    name: 'Management_Module_List',
    hidden: true,
    component: () => import('@/views/devicemaster/managementmodule/main/management_module_main.vue'),
    meta: {
      title: '管理モジュール',
      roles: ['SB_ADMIN', 'SB_OPR'],
      authorities: ['AUTH_MANAGEMENT_MODULE_LIST']
    }
  },
  {
    path: '/device/master/management-module/show',
    name: 'Management_Module_Show',
    hidden: true,
    component: () => import('@/views/devicemaster/managementmodule/detail/management_module_show.vue'),
    meta: {
      title: ' 管理モジュール詳細',
      activeMenu: { path: '/device/master/management-module/list', title: '管理モジュール' },
      roles: ['SB_ADMIN', 'SB_OPR'],
      authorities: ['AUTH_MANAGEMENT_MODULE_READ']
    }
  },
  {
    path: '/device/master/management-module/create',
    name: 'Management_Module_Create',
    component: () => import('@/views/devicemaster/managementmodule/edit/management_module_create.vue'),
    hidden: true,
    meta: {
      title: '管理モジュール新規登録',
      activeMenu: { path: '/device/master/management-module/list', title: '管理モジュール' },
      roles: ['SB_ADMIN'],
      authorities: ['AUTH_MANAGEMENT_MODULE_CREATE']
    }
  },
  {
    path: '/device/master/management-module/edit',
    name: 'Management_Module_Edit',
    component: () => import('@/views/devicemaster/managementmodule/edit/management_module_edit.vue'),
    hidden: true,
    meta: {
      title: '管理モジュール編集',
      activeMenu: { path: '/device/master/management-module/list', title: '管理モジュール' },
      roles: ['SB_ADMIN'],
      authorities: ['AUTH_MANAGEMENT_MODULE_UPDATE']
    }
  },
  {
    path: '/device/master/management-module/version_create',
    name: 'Management_Module_Version_Create',
    component: () => import('@/views/devicemaster/managementmodule/version/version_create.vue'),
    hidden: true,
    meta: {
      title: 'バージョン作成',
      activeMenu: { path: '/device/master/management-module/list', title: '管理モジュール' },
      roles: ['SB_ADMIN'],
      authorities: ['AUTH_MANAGEMENT_MODULE_VERSION_CREATE']
    }
  },
  {
    path: '/device/master/management-module/version_edit',
    name: 'Management_Module_Version_Edit',
    component: () => import('@/views/devicemaster/managementmodule/version/version_edit.vue'),
    hidden: true,
    meta: {
      title: 'バージョン編集',
      activeMenu: { path: '/device/master/management-module/list', title: '管理モジュール' },
      roles: ['SB_ADMIN'],
      authorities: ['AUTH_MANAGEMENT_MODULE_VERSION_UPDATE']
    }
  }
]

const deviceMasterRouter = {
  path: '/device/master',
  component: LayoutNoMenu,
  name: 'DeviceMaster',
  meta: { title: 'デバイスマスタ管理', svg: 'system' },
  children: [
    ...edgeboxKind,
    ...ManagementModule
  ]
}

export default deviceMasterRouter
