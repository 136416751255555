import request from '@/common/request'
import Qs from 'qs'

export function searchEdgeBoxesByPaging_api(searchText, init, pagination, sorts) {
  const params = {
    searchWord: searchText,
    init: init,
    ...pagination,
    sorts: sorts || []
  }
  return request({
    url: '/edgeboxes?' + Qs.stringify(params),
    method: 'get'
  })
}

export function searchEdgeBox_api(edgeBoxId) {
  return request({
    url: '/edgeboxes/' + edgeBoxId,
    method: 'get'
  })
}

export function searchEdgeBoxNoLog_api(edgeBoxId) {
  return request({
    url: '/edgeboxes/' + edgeBoxId + '/no-log',
    method: 'get'
  })
}

export function rebootEdgeBox(edgeBoxId) {
  return request({
    url: '/edgeboxes/' + edgeBoxId + '/reboot',
    method: 'post'
  })
}

export function updateEdgeBoxFirmware(edgeBoxId) {
  return request({
    url: '/edgeboxes/' + edgeBoxId + '/request-fota',
    method: 'post'
  })
}

export function rebootEdgeBoxes_api(edgeBoxList) {
  const edgeBoxInfoList = []
  edgeBoxList.forEach(e => {
    if (e.$selection) {
      edgeBoxInfoList.push({
        edgeBoxId: e.edgeboxId,
        edgeBoxName: e.edgeboxName
      })
    }
  })
  const param = {
    edgeboxInfoList: edgeBoxInfoList
  }
  return request({
    url: '/edgeboxes/reboot',
    method: 'post',
    data: param,
    headers: { 'Content-Type': 'application/json;charset=utf-8' }
  })
}

export function updateEdgeBoxFirmwares_api(edgeBoxList) {
  const edgeBoxInfoList = []
  edgeBoxList.forEach(e => {
    if (e.$selection) {
      edgeBoxInfoList.push({
        edgeBoxId: e.edgeboxId,
        edgeBoxName: e.edgeboxName
      })
    }
  })
  const param = {
    edgeboxInfoList: edgeBoxInfoList
  }
  return request({
    url: '/edgeboxes/request-fota',
    method: 'post',
    data: param,
    headers: { 'Content-Type': 'application/json;charset=utf-8' }
  })
}

export function createEdgebox_api(edgeBoxName) {
  const params = {
    edgeBoxName: edgeBoxName
  }
  return request({
    url: '/edgeboxes',
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'application/json;charset=utf-8' }
  })
}

export function deleteEdgeBox_api(edgeBoxId) {
  return request({
    url: '/edgeboxes/' + edgeBoxId,
    method: 'delete'
  })
}

export function edgeBoxLogsDeviceRequest_api(edgeBoxId) {
  return request({
    url: `/edgeboxes/${edgeBoxId}/devices/request`,
    method: 'get'
  })
}

export function edgeBoxLogsApplicationRequest_api(edgeBoxId) {
  return request({
    url: `/edgeboxes/${edgeBoxId}/applications/request`,
    method: 'get'
  })
}

export function downloadDetection_api(params) {
  return request({
    url: `/edgeboxes/download/detection`,
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'application/json;charset=utf-8' }
  })
}

export function edgeBoxLogsDeviceDownloadUrl(edgeBoxId, requestId, edgeBoxName, duration) {
  const params = {
    requestId: requestId,
    edgeBoxName: edgeBoxName,
    duration: duration
  }
  return request({
    url: `/edgeboxes/${edgeBoxId}/devices/download`,
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'application/json;charset=utf-8' }
  })
}

export function edgeBoxLogsApplicationDownloadUrl(edgeBoxId, requestId, edgeBoxName, duration) {
  const params = {
    requestId: requestId,
    edgeBoxName: edgeBoxName,
    duration: duration
  }
  return request({
    url: `/edgeboxes/${edgeBoxId}/applications/download`,
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'application/json;charset=utf-8' }
  })
}

export function updateEdgeBox_api(edgeBoxForm) {
  const params = {
    ...(edgeBoxForm.edgeBoxName && { edgeBoxName: edgeBoxForm.edgeBoxName }),
    ...(edgeBoxForm.fotaSchedule && { fotaSchedule: edgeBoxForm.fotaSchedule }),
    ...(edgeBoxForm.updateTag && { tags: edgeBoxForm.updateTag })
  }
  return request({
    url: `/edgeboxes/${edgeBoxForm.edgeBoxId}`,
    method: 'patch',
    data: params,
    headers: { 'Content-Type': 'application/json;charset=utf-8' }
  })
}

export function searchEdgeBoxWithManagementModule_api(edgeBoxId) {
  return request({
    url: '/edgeboxes/' + edgeBoxId + '/management-modules',
    method: 'get'
  })
}

export function updateEdgeboxManagementModuleRelation_api(edgeBoxId, edgeboxManagementModuleList) {
  const params = {
    managementModuleList: edgeboxManagementModuleList
  }
  return request({
    url: '/edgeboxes/' + edgeBoxId + '/management-modules',
    method: 'patch',
    data: params,
    headers: { 'Content-Type': 'application/json;charset=utf-8' }
  })
}

export function updateDeviceInfo_api(edgeBoxId) {
  return request({
    url: `/edgeboxes/${edgeBoxId}/update-device-info`,
    method: 'post'
  })
}

export function updateEdgeboxStatus_api(edgeBoxId) {
  return request({
    url: `/edgeboxes/${edgeBoxId}/latest-status`,
    method: 'get'
  })
}

export function resetLoginInfo_api(edgeBoxId) {
  return request({
    url: '/edgeboxes/' + edgeBoxId + '/reset-login-info',
    method: 'post'
  })
}

export function getCurrentEdgeboxRequestIds() {
  return request({
    url: `/edgeboxes/log-file-download/request-ids`,
    method: 'get'
  })
}

export function initCreateEdgeBox_api() {
  return request({
    url: '/edgeboxes/init',
    method: 'get'
  })
}

export function initUpdateEdgeBox_api(edgeBoxId) {
  return request({
    url: '/edgeboxes/' + edgeBoxId + '/init',
    method: 'get'
  })
}

export function initUpdateEdgeBoxManagementModuleRelation_api(edgeBoxId) {
  return request({
    url: '/edgeboxes/' + edgeBoxId + '/management-modules/init',
    method: 'get'
  })
}
