<template>
  <div class="page-header">
    <div :style="{'width': backWidth + 'px'}" class="text back">
      <el-link :underline="false" type="primary" class="back-text" @click="handleTitleClick">{{ titleText }}</el-link>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  name: 'BackLink',
  props: {
    title: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 100
    },
    titleClick: {
      type: Function,
      default: () => () => {
      }
    },
    goBack: {
      type: Function,
      default: null
    }
  },
  setup(props) {
    const router = useRouter()
    const route = useRoute()

    const backWidth = computed(() => props.width + 65)
    const titleText = computed(() => props.title || route.meta.title || '')

    const handleTitleClick = () => {
      if (props.goBack) {
        props.goBack()
      } else {
        router.go(-1)
      }
    }

    return {
      backWidth,
      titleText,
      handleTitleClick
    }
  }
})
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .page-header {
    height: 24px;
    display: flex;
    line-height: 24px;
    margin-bottom: 24px;
  }
  .page-header .text {
    display: block;
    margin-left: 1px;
    text-align: left;
    height: 24px;
    line-height: 24px;
    font-size: 14px;
  }
  .back-text {
    font-weight: 500;
  }
</style>
