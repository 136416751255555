import request from '@/common/request'
import Qs from 'qs'

export function searchVideoList_api(searchText, init, pagination, sorts) {
  const params = {
    cameraName: searchText,
    ...pagination,
    init: init,
    sorts: sorts || []
  }
  return request({
    url: '/cameras?' + Qs.stringify(params),
    method: 'get'
  })
}

export function searchCameraVideos(cameraKey, sorts, startDatetime, endDatetime) {
  const params = {
    sorts: sorts || [],
    startDatetime: startDatetime,
    endDatetime: endDatetime
  }
  return request({
    url: `/media/cameras/${cameraKey}/videos?` + Qs.stringify(params),
    method: 'get'
  })
}

export function searchCameraImages(cameraKey) {
  return request({
    url: `/media/cameras/${cameraKey}/images`,
    method: 'get'
  })
}

export function downloadRequest(cameraKey, streamKey, streamName, eventId, startDatetime, endDatetime, downloadStartDatetime, downloadEndDatetime) {
  const params = {
    streamKey: streamKey,
    streamName: streamName,
    eventId: eventId,
    startDatetime: startDatetime,
    endDatetime: endDatetime,
    downloadStartDatetime: downloadStartDatetime,
    downloadEndDatetime: downloadEndDatetime
  }
  return request({
    url: `/media/cameras/${cameraKey}/videos/request`,
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'application/json;charset=utf-8' }
  })
}

export function downloadUrl(requestId) {
  return request({
    url: `/media/videos/requests/${requestId}/download`,
    method: 'get'
  })
}

export function imagesDownloadRequest(cameraKey, streamKey, streamName) {
  const params = {
    streamKey: streamKey,
    streamName: streamName
  }
  return request({
    url: `/media/cameras/${cameraKey}/images/request`,
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'application/json;charset=utf-8' }
  })
}

export function imagesDownloadUrl(requestId) {
  return request({
    url: `/media/images/requests/${requestId}/download`,
    method: 'get'
  })
}

export function downloadDetection_api(params) {
  return request({
    url: `/media/video-image-requests/detection`,
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'application/json;charset=utf-8' }
  })
}

export function getCurrentVideoRequestIds() {
  return request({
    url: `/media/video-image-download/request-ids`,
    method: 'get'
  })
}

export function videoDownloadUrlForPlayback(cameraKey, streamKey, streamName, eventId, downloadStartDatetime, downloadEndDatetime) {
  const params = {
    streamKey: streamKey,
    streamName: streamName,
    eventId: eventId,
    downloadStartDatetime: downloadStartDatetime,
    downloadEndDatetime: downloadEndDatetime
  }
  return request({
    url: `/media/video-download/cameras/${cameraKey}`,
    timeout: 480 * 10 ** 3, // msec
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'application/json;charset=utf-8' }
  })
}
