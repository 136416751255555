<template>
  <div>
    <div role="dialog" tabindex="-1" aria-modal="true" class="el-message-box__wrapper">
      <div class="el-message-box">
        <div class="el-message-box__header">
          <div class="el-message-box__title">
            <span>{{ title }}</span>
          </div>
          <button type="button" aria-label="Close" class="el-message-box__headerbtn" @click="closeModal">
            <el-icon class="el-message-box__close">
              <Close />
            </el-icon>
          </button>
        </div>
        <div class="el-message-box__content">
          <slot/>
        </div>
      </div>
    </div>
    <div class="v-modal" tabindex="0"/>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted } from 'vue';

export default defineComponent({
  name: 'FullScreenModal',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  setup(_, { emit }) {
    onMounted(() => {
      document.body.style.overflow = 'hidden'
    })
    onBeforeUnmount(() => {
      document.body.style.overflow = 'auto'
    })
    const closeModal = () => {
      emit('close')
    }

    return { closeModal }
  }
})
</script>

<style scoped>
  .el-message-box {
    min-width: 1184px !important;
    max-width: 1920px !important;
    margin: 48px;
    width: calc(100vw - 96px) !important;
    height: calc(100vh - 96px);
    margin-bottom: 48px;
    padding-bottom: 0;
  }
  .el-message-box__wrapper {
    z-index: 2001;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  .el-message-box__content {
    height: calc(100% - 98px);
    padding: 0
  }
  .v-modal {
    z-index: 2000;
  }
</style>
