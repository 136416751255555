import { createApp, h, onMounted, onUnmounted } from 'vue'
import axios from 'axios'

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import 'normalize.css/normalize.css' // A modern alternative to CSS resets

import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import { ElConfigProvider } from 'element-plus'
import '@/assets/styles/element-variables.scss'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'virtual:svg-icons-register'


import '@/assets/styles/index.scss' // global css
import '@/assets/iconfont/iconfont.css'

import { setupGlobalComponents } from '@/components/Control'
import SvgIcon from '@/components/SvgIcon.vue'

import App from './App.vue'
import { store } from './common/store'
import { router } from './common/router'

import '@/common/router/permission'
import { setDirective } from '@/common/directive'
import { Utils } from '@/utils'

import echarts from 'echarts' // echarts
import screenfull from 'screenfull'

const app = createApp({
  setup() {
    IE11RouterFix(router)
  },
  render: () => h(App)
})
window.app = app

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

// use plugin
app.use(ElementPlus, { size: 'small' })
app.use(router)
app.use(store)
app.component('el-config-provider', ElConfigProvider)

setupGlobalComponents(app)
app.component('svg-icon', SvgIcon)
setDirective(app)

app.config.globalProperties.$utils = Utils
app.config.globalProperties.$echarts = echarts
app.config.globalProperties.$screenfull = screenfull

app.config.globalProperties.$http = axios
app.config.globalProperties.$getConfig = function() {
  return axios.get('/static/config.json').then(res => {
    app.config.globalProperties.detectionInterval = res.data.detectionInterval
    app.config.globalProperties.edgeboxLogInterval = res.data.edgeboxLogInterval
    app.config.globalProperties.edgeboxStatusInterval = res.data.edgeboxStatusInterval
    app.config.globalProperties.edgeboxStatusIntervalTimeout = res.data.edgeboxStatusIntervalTimeout
    app.config.globalProperties.batchRebootNumMax = res.data.batchRebootNumMax
    app.config.globalProperties.batchUpdateFirmwareNumMax = res.data.batchUpdateFirmwareNumMax
    app.config.globalProperties.analysisDownloadBetweenDays = res.data.analysisDownloadBetweenDays
    app.config.globalProperties.analysisResultBetweenDays = res.data.analysisResultBetweenDays
  }).catch(err => {
    console.log(err)
  })
}

function IE11RouterFix(router) {
  const hashChangeHandler = () => {
    router.push(window.location.hash.substring(1, window.location.hash.length))
  }
  const isIE11 = !!window.MSInputMethodContext && !!document.documentMode
  onMounted(() => {
    if (isIE11) {
      window.addEventListener('hashchange', hashChangeHandler)
    }
  })
  onUnmounted(() => {
    if (isIE11) { 
      window.removeEventListener('hashchange', hashChangeHandler)
    }
  })
}

app.config.globalProperties.$getConfig().then(() => {
  app.mount('#app')
})
