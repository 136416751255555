import { Utils } from '@/utils'
import { getCurrentVideoRequestIds } from '@/api/video'
import { hasPermission } from '@/utils/permission'

const detection = {
  state: {
    time: 0,
    downloadList: [], // [{cameraKey: 'xxx', startDatetime: 'xxx', endDatetime: 'xxx'}]
    videoIntervalId: null
  },

  mutations: {
    UPDATE_TIME: (state, date) => {
      state.time = date
    },
    CLEAR_VIDEO_INTERVAL_ID: (state) => {
      state.videoIntervalId = null
    },
    SET_VIDEO_INTERVAL_ID: (state, intervalId) => {
      state.videoIntervalId = intervalId
    },
    CLEAR: (state) => {
      const detectionNew = {
        time: 0,
        downloadList: []
      }

      if (!Utils.getLocalStorage('detection') || !Utils.getLocalStorage('detection').time || !Utils.getLocalStorage('detection').downloadList) {
        Utils.saveLocalStorage('detection', detectionNew)
      }

      if (Utils.getLocalStorage('detection')) {
        const detectionLocal = Utils.getLocalStorage('detection')
        detectionLocal.downloadList = []
        Utils.saveLocalStorage('detection', detectionLocal)
      }
    }
  },

  actions: {
    clearVideoIntervalId({ commit }) {
      commit('CLEAR_VIDEO_INTERVAL_ID')
    },
    setVideoIntervalId({ commit }, intervalId) {
      commit('SET_VIDEO_INTERVAL_ID', intervalId)
    },
    clearAllDetectionDownload({ commit }) {
      commit('CLEAR')
      commit('UPDATE_TIME', 0)
    },
    async resetDetectionDownload({ commit }) {
      commit('CLEAR')
      if (hasPermission(['AUTH_CAMERA_VIDEO'])) {
        // TenantId、userIdに従って、現在のデータベースのリクエストを取得します
        await getCurrentVideoRequestIds().then(res => {
          const requestIds = res.value.requestIds
          if (requestIds && requestIds.length > 0) {
            commit('UPDATE_TIME', new Date().getTime())
            Utils.updateDetectionDownloadList(requestIds)
          } else {
            const videoIntervalId = this.$store.getters.videoIntervalId
            clearInterval(videoIntervalId)
            this.$store.dispatch('clearVideoIntervalId')
          }
        }).catch((e) => {
        }).finally(() => {
        })
      }
    }
  }
}

export default detection
