export const Utils = {

  isEmpty: function(text) {
    return !text || text.trim().length === 0
  },
  guid: function() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0
      const v = c === 'x' ? r : (r & 0x3 | 0x8)
      return v.toString(16)
    })
  },
  uuid: function() {
    const s = []
    const hexDigits = '0123456789abcdef'
    for (let i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    }
    s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = '-'
    return s.join('')
  },

  /**
   * @param {string} url
   * @returns {Object}
   */
  param2Obj: function(url) {
    const search = url.split('?')[1]
    if (!search) {
      return {}
    }
    return JSON.parse(
      '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')
        .replace(/\+/g, ' ') +
      '"}'
    )
  },
  sort: function(column, mapping) {
    if (column === null || !column.order || column.order === 'null') return ''
    let prop = column.property
    if (mapping && mapping[column.property]) {
      prop = mapping[column.property]
    }
    return prop + ':' + column.order
  },
  fillFile: function(add, formData, file) {
    if (file) {
      if (add) {
        formData.append('file', file.raw)
      } else {
        if (file.update === true) {
          if (file.raw) {
            formData.append('file', file.raw)
          }
        }
        formData.append('fileUpdate', file.update)
      }
    }
  },
  imgToBase64: function(file, callback) {
    const URL = window.URL || window.webkitURL
    const url = URL.createObjectURL(file)
    // 写真をBase 64に変換します。
    let canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const img = new Image()
    img.crossOrigin = 'Anonymous'
    img.onload = function() {
      canvas.height = img.height
      canvas.width = img.width
      ctx.drawImage(img, 0, 0)
      const base64 = canvas.toDataURL(file.type)
      callback(base64, img.width, img.height)
      canvas = null
    }
    img.src = url
  },

  download: function(response) {
    const contentType = response.headers['content-type']
    const ct = contentType.split(';')
    const fileType = ct[0] || null
    let fileName = ct[1].split('=')[1]
    const blob = new Blob([response.data], {
      type: fileType
    })

    fileName = decodeURI(fileName)
    if (window.navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, fileName)
    } else {
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = fileName
      link.click()
      window.URL.revokeObjectURL(link.href)
    }
  },
  saveLocalStorage: function(key, object) {
    localStorage.setItem(key, JSON.stringify(object))
  },
  getLocalStorage: function(key) {
    const value = localStorage.getItem(key)
    if (value) {
      return JSON.parse(value)
    }
    return {}
  },
  updateDetectionTime: function(time) {
    const detection = Utils.getLocalStorage('detection')
    detection.time = time
    Utils.saveLocalStorage('detection', detection)
  },
  updateDetectionDownloadList: function(downloadList) {
    const detection = Utils.getLocalStorage('detection')
    detection.downloadList = downloadList
    Utils.saveLocalStorage('detection', detection)
  },
  updateLogTime: function(logTime) {
    const log = Utils.getLocalStorage('log')
    log.logTime = logTime
    Utils.saveLocalStorage('log', log)
  },
  updateLogDownloadList: function(logDownloadList) {
    const log = Utils.getLocalStorage('log')
    log.logDownloadList = logDownloadList
    Utils.saveLocalStorage('log', log)
  }
}
