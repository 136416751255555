import { store } from '@/common/store'

export default {
  mounted(el, binding, vnode) {
    const { value } = binding
    const authorities = store.getters.authorities

    if (value && value instanceof Array && value.length > 0) {
      const permissionAuthorities = value

      const hasPermission = authorities.some(auth => {
        return permissionAuthorities.includes(auth)
      })

      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    }
  }
}
