<template>
  <div class="pagination-panel el-pagination">
    <button :disabled="currentPage <= 1" type="button" class="btn-prev" @click="handlePrevious">
      <el-icon>
        <ArrowLeft/>
      </el-icon>
      <i class="el-icon el-icon-arrow-left" />
    </button>
    <ul class="el-pager">
      <li class="number active">{{ currentPage }}</li>
    </ul>
    <button :disabled="!nextCursor || nextCursor === ''" type="button" class="btn-next" @click="handleNext">
      <el-icon>
        <ArrowRight/>
      </el-icon>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  props: {
    previousCursor: {
        type: String,
        required: true
    },
    nextCursor: {
        type: String,
        required: true
    },
    currentPage: {
        type: Number,
        required: true
    }
  },
  setup(props, { emit }) {
    const pageNumber = ref<number>(0)
    const handleNext = () => {
      emit('current-change', props.currentPage + 1, props.nextCursor, 1)
    }
    const handlePrevious = () => {
      emit('current-change', props.currentPage - 1, props.previousCursor, -1)
    }
    return {
      pageNumber,
      handleNext,
      handlePrevious
    }
  }
})
</script>
