import { downloadDetection_api, downloadUrl, imagesDownloadUrl } from '@/api/video'
import { Messages } from '@/utils/messages'
import { Utils } from '@/utils'
import { isEmpty } from '@/utils/validate'
import { store } from '@/common/store'
import { event } from '@/common/router/router_event'
import JsonConfig from '#/config.json'

window.downloadDetectionMessages = {}
export default {
  name: 'VideoDownloadDetection',
  setup() {
    let videoIntervalId = ref(store.getters.videoIntervalId)
    onMounted(() => {
      const detectionIntervalConfig = JsonConfig.detectionInterval
      const downloadList = Utils.getLocalStorage('detection').downloadList
      if (videoIntervalId.value) {
        clearInterval(videoIntervalId.value)
        store.dispatch('clearVideoIntervalId')
      }
      if (downloadList && downloadList.length > 0) {
        videoIntervalId.value = setInterval(handleDetection, detectionIntervalConfig)
        store.dispatch('setVideoIntervalId', videoIntervalId.value)
      }
    })
  }
}

export function initVideoInterval() {
  let videoIntervalId = store.getters.videoIntervalId
  const detectionIntervalConfig = JsonConfig.detectionInterval
  if (videoIntervalId) {
    clearInterval(videoIntervalId)
    store.dispatch('clearVideoIntervalId')
  }
  videoIntervalId = setInterval(handleDetection, detectionIntervalConfig)
  store.dispatch('setVideoIntervalId', videoIntervalId)
}

export function handleDetection() {
  let videoIntervalId = store.getters.videoIntervalId
  const downloadList = Utils.getLocalStorage('detection').downloadList
  if (downloadList && downloadList.length > 0) {
    downloadDetection_api(downloadList).then(res => {
      const complete = res.data.success
      if (complete && complete.length > 0) {
        handleCompleteDetection(complete)
        event.emit('videoDownloadComplete', complete)
      }
      const error = res.data.error
      if (error && error.length > 0) {
        handleErrorDetection(error)
        event.emit('videoDownloadError', error)
      }
    }).catch(() => {
    })
  } else {
    clearInterval(videoIntervalId)
    videoIntervalId = null
    store.dispatch('clearVideoIntervalId')
  }

  Utils.updateDetectionTime(new Date().getTime())
}

export function handleCompleteDetection(complete) {
  complete.forEach(item => {
    setTimeout(() => {
      if (item.requestKind === '0') {
        downloadUrl(item.requestId).then(urlResponse => {
          const url = urlResponse.value
          if (typeof (url) !== 'undefined') {
            const texts = Messages.DVS_FT_I0006.split('\n')
            Messages.successHasMsgFromBackend(texts)
            handleLogDownload(url)
          }
        }).catch(e => {
        })
      }
      if (item.requestKind === '1') {
        imagesDownloadUrl(item.requestId).then(urlResponse => {
          const url = urlResponse.value
          if (typeof (url) !== 'undefined') {
            const texts = Messages.DVS_FT_I0007.split('\n')
            Messages.successHasMsgFromBackend(texts)
            handleLogDownload(url)
          }
        }).catch(e => {
        })
      }
    }, 200)
  })
  downloadDetectionSplice(complete) // delete
}

export function handleLogDownload(url) {
  const iframe = document.createElement('iframe')
  iframe.style.display = 'none'
  iframe.style.height = 0
  iframe.src = url
  document.body.appendChild(iframe)
  setTimeout(() => {
    iframe.remove()
  }, 5 * 60 * 1000)
}

export function handleErrorDetection(error) {
  let videoInfo = ''
  let imagesInfo = ''
  error.forEach(item => {
    if (item.cameraName) {
      if (item.requestKind === '0') {
        videoInfo = videoInfo + item.cameraName + '  ' + item.videoStartAndEndTime + '\n'
      } else if (item.requestKind === '1') {
        imagesInfo = imagesInfo + item.cameraName + '  ' + item.streamName + '\n'
      }
    }
  })
  setTimeout(() => {
    if (!isEmpty(videoInfo)) {
      const texts = Messages.DVS_FT_E0004(videoInfo).split('\n')
      Messages.errorHasMsgFromBackend(texts)
    }
  }, 200)
  setTimeout(() => {
    if (!isEmpty(imagesInfo)) {
      const texts = Messages.DVS_FT_E0009(imagesInfo).split('\n')
      Messages.errorHasMsgFromBackend(texts)
    }
  }, 200)
  downloadDetectionSplice(error) // delete
}

export function downloadDetectionSplice(complete) {
  const detection = Utils.getLocalStorage('detection')
  const downloadList = detection.downloadList || []
  const indexs = []
  complete.forEach(d => {
    downloadList.forEach((dd, index) => {
      if (String(d.requestId) === String(dd)) {
        indexs.push(index)
      }
    })
  })
  if (indexs.length > 0) {
    indexs.reverse().forEach(index => {
      downloadList.splice(index, 1)
    })
  }
  Utils.saveLocalStorage('detection', detection)
}
