import request from '@/common/request'

export function searchByPaging(text, init) {
  const params = {
    tenantName: null,
    searchWord: text,
    init: init
  }
  return request({
    url: '/tenants',
    method: 'get',
    params: params
  })
}

export function searchTenants(text, init) {
  const params = {
    tenantName: text,
    searchWord: null,
    init: init
  }
  return request({
    url: '/tenants/dialog',
    method: 'get',
    params: params
  })
}

export function getTenantDetail() {
  return request({
    url: '/tenants/detail',
    method: 'get',
    headers: { 'Content-Type': 'application/json;charset=utf-8' }
  })
}

export function getTenantDetailNoLog() {
  return request({
    url: '/tenants/detail/no-log',
    method: 'get',
    headers: { 'Content-Type': 'application/json;charset=utf-8' }
  })
}

export function getTenantServiceStopFlagDetail() {
  return request({
    url: '/tenants/service-status',
    method: 'get',
    headers: { 'Content-Type': 'application/json;charset=utf-8' }
  })
}

export function chooseTenant_api(tenant_id) {
  return request({
    url: '/tenants/' + tenant_id + '/switch',
    method: 'put',
    params: { tenant_id }
  })
}

export function changePlatformKey_api(tenantForm) {
  return request({
    url: '/tenants/pf-keys',
    method: 'patch',
    data: tenantForm,
    headers: { 'Content-Type': 'application/json;charset=utf-8' }
  })
}

export function enablePFKey_api() {
  return request({
    url: '/tenants/pf-keys/enable',
    method: 'patch',
    headers: { 'Content-Type': 'application/json;charset=utf-8' }
  })
}

export function disablePFKey_api() {
  return request({
    url: '/tenants/pf-keys/disable',
    method: 'patch',
    headers: { 'Content-Type': 'application/json;charset=utf-8' }
  })
}

export function changeNotificationUrl(notificationUrl) {
  const params = {
    notificationUrl: notificationUrl
  }
  return request({
    url: '/tenants/notification-urls',
    method: 'patch',
    data: params,
    headers: { 'Content-Type': 'application/json;charset=utf-8' }
  })
}

export function initCreateTenant() {
  return request({
    url: '/tenants/create/init',
    method: 'get'
  })
}

export function createTenant(tenantForm) {
  const patternInfoModel = {
    patternId: tenantForm.patternId,
    volume: tenantForm.patternVolume
  }
  const optionsInfoModel = null
  const dataInfoModel = {
    serviceId: tenantForm.serviceId,
    subscriptionId: tenantForm.subscriptionId,
    tenantName: tenantForm.tenantName,
    accountStartDate: tenantForm.accountStartDate,
    serviceStartDate: tenantForm.serviceStartDate,
    billingStartDate: tenantForm.billingStartDate,
    pattern: patternInfoModel,
    options: optionsInfoModel
  }
  const params = {
    data: dataInfoModel
  }
  return request({
    url: '/tenants',
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'application/json;charset=utf-8' }
  })
}

export function updateTenant(tenantName) {
  const params = {
    tenantName: tenantName
  }
  return request({
    url: '/tenants',
    method: 'patch',
    data: params,
    headers: { 'Content-Type': 'application/json;charset=utf-8' }
  })
}

export function startTenantService() {
  return request({
    url: '/tenants/activate',
    method: 'put'
  })
}

export function stopTenantService() {
  return request({
    url: '/tenants/deactivate',
    method: 'put'
  })
}

export function initUpdateTenant() {
  return request({
    url: '/tenants/init',
    method: 'get'
  })
}

export function initChangeNotificationUrl() {
  return request({
    url: '/tenants/notification-urls/init',
    method: 'get'
  })
}

export function deleteTenant(tenantId) {
  return request({
    url: '/tenants/' + tenantId,
    method: 'delete',
    params: { tenantId }
  })
}
