import Layout from '../../../layout/Layout.vue'

const edgebox = [
  {
    path: '/device/edgebox/list',
    name: 'Tenant_EdgeBox_List',
    component: () => import('@/views/device/edgebox/main/edgebox_main.vue'),
    meta: {
      title: 'エッジボックス',
      svg: 'edge-ai-box',
      roles: ['SB_ADMIN', 'SB_OPR', 'USER_ADMIN', 'USER_OPR'],
      authorities: ['AUTH_EDGEBOX_LIST']
    }
  },
  {
    path: '/device/edgebox/show',
    name: 'Tenant_EdgeBox_Show',
    hidden: true,
    component: () => import('@/views/device/edgebox/detail/edgebox_show.vue'),
    meta: {
      title: ' エッジボックス詳細',
      activeMenu: { path: '/device/edgebox/list', title: 'エッジボックス' },
      roles: ['SB_ADMIN', 'SB_OPR', 'USER_ADMIN', 'USER_OPR'],
      authorities: ['AUTH_EDGEBOX_READ']
    }
  },
  {
    path: '/device/edgebox/create',
    name: 'Tenant_EdgeBox_Create',
    component: () => import('@/views/device/edgebox/edit/edgebox_create.vue'),
    hidden: true,
    meta: {
      title: 'エッジボックス新規登録',
      activeMenu: { path: '/device/edgebox/list', title: 'エッジボックス' },
      roles: ['SB_ADMIN', 'USER_ADMIN'],
      authorities: ['AUTH_EDGEBOX_CREATE']
    }
  },
  {
    path: '/device/edgebox/edit',
    name: 'Tenant_EdgeBox_Edit',
    component: () => import('@/views/device/edgebox/edit/edgebox_edit.vue'),
    meta: {
      title: 'エッジボックス名編集',
      activeMenu: { path: '/device/edgebox/list', title: 'エッジボックス' },
      roles: ['SB_ADMIN', 'USER_ADMIN'],
      authorities: ['AUTH_EDGEBOX_UPDATE']
    },
    hidden: true
  },
  {
    path: '/device/edgebox/management-module/edit',
    name: 'Tenant_EdgeBox_Management_Module_Edit',
    component: () => import('@/views/device/edgebox/managementmodule/edgebox_management_module_edit.vue'),
    hidden: true,
    meta: {
      title: '管理モジュールの編集',
      activeMenu: { path: '/device/edgebox/list', title: 'エッジボックス' },
      roles: ['SB_ADMIN', 'USER_ADMIN'],
      authorities: ['AUTH_EDGEBOX_MANAGEMENT_MODULE']
    }
  },
  {
    path: '/device/edgebox/tag/edit',
    name: 'Tenant_EdgeBox_Tag_Edit',
    component: () => import('@/views/common/tag/edit/tag_edit.vue'),
    hidden: true,
    meta: {
      title: 'タグの編集',
      activeMenu: { path: '/device/edgebox/list', title: 'エッジボックス' },
      roles: ['SB_ADMIN', 'USER_ADMIN'],
      authorities: ['AUTH_EDGEBOX_UPDATE']
    }
  }
]

const camera = [
  {
    path: '/device/camera/list',
    name: 'Tenant_Camera_List',
    component: () => import('@/views/device/camera/main/camera_main.vue'),
    meta: {
      title: 'カメラ',
      icon: 'VideoCamera',
      roles: ['SB_ADMIN', 'SB_OPR', 'USER_ADMIN', 'USER_OPR'],
      authorities: ['AUTH_CAMERA_LIST']
    }
  },
  {
    path: '/device/camera/show',
    name: 'Tenant_Camera_Show',
    hidden: true,
    component: () => import('@/views/device/camera/detail/camera_show.vue'),
    meta: {
      title: ' カメラ詳細',
      activeMenu: { path: '/device/camera/list', title: 'カメラ' },
      roles: ['SB_ADMIN', 'SB_OPR', 'USER_ADMIN', 'USER_OPR'],
      authorities: ['AUTH_CAMERA_READ']
    }
  },
  {
    path: '/device/camera/edit/algorithm',
    name: 'Tenant_Camera_Algorithm_Create',
    component: () => import('@/views/device/camera/algorithm/camera_algorithm_edit.vue'),
    hidden: true,
    meta: {
      title: 'AIアプリ変更',
      activeMenu: { path: '/device/camera/list', title: 'カメラ' },
      roles: ['SB_ADMIN', 'USER_ADMIN'],
      authorities: ['AUTH_CAMERA_ALGORITHM']
    }
  },
  {
    path: '/device/camera/execute/algorithm/method',
    name: 'Tenant_Camera_Algorithm_Method_Execute',
    component: () => import('@/views/device/camera/method/method_edit.vue'),
    hidden: true,
    meta: {
      title: '任意メソッドの実行',
      activeMenu: { path: '/device/camera/list', title: 'カメラ' },
      roles: ['SB_ADMIN', 'USER_ADMIN'],
      authorities: ['AUTH_CAMERA_METHOD_EXECUTE']
    }
  },
  {
    path: '/device/camera/name/edit',
    name: 'Camera_Name_Edit',
    component: () => import('@/views/device/camera/edit/camera_name_edit.vue'),
    meta: {
      title: 'カメラ名編集',
      activeMenu: { path: '/device/camera/list', title: 'カメラ' },
      authorities: ['AUTH_CAMERA_UPDATE']
    },
    hidden: true
  },
  {
    path: '/device/camera/edit/link',
    name: 'Tenant_Camera_Link_Create',
    component: () => import('@/views/device/camera/link/camera_link_edit.vue'),
    hidden: true,
    meta: {
      title: '紐付け対象カメラ変更',
      activeMenu: { path: '/device/camera/list', title: 'カメラ' },
      roles: ['SB_ADMIN', 'USER_ADMIN'],
      authorities: ['AUTH_CAMERA_UPDATE']
    }
  },
  {
    path: '/device/camera/tag/edit',
    name: 'Tenant_Camera_Tag_Edit',
    component: () => import('@/views/common/tag/edit/tag_edit.vue'),
    hidden: true,
    meta: {
      title: 'タグの編集',
      activeMenu: { path: '/device/camera/list', title: 'カメラ' },
      roles: ['SB_ADMIN', 'USER_ADMIN'],
      authorities: ['AUTH_CAMERA_UPDATE']
    }
  }
]

const algorithm = [
  {
    path: '/device/algorithm/list',
    name: 'Tenant_Algorithm_List',
    component: () => import('@/views/device/algorithm/main/algorithm_tenant_main.vue'),
    meta: {
      title: 'AIアプリ',
      icon: 'Cpu',
      roles: ['SB_ADMIN', 'SB_OPR', 'USER_ADMIN', 'USER_OPR'],
      authorities: ['AUTH_ALGORITHM_LIST']
    }
  },
  {
    path: '/device/algorithm/show',
    name: 'Tenant_Algorithm_Show',
    hidden: true,
    component: () => import('@/views/device/algorithm/detail/algorithm_show.vue'),
    meta: {
      title: ' AIアプリ詳細',
      activeMenu: { path: '/device/algorithm/list', title: 'AIアプリ' },
      roles: ['SB_ADMIN', 'SB_OPR', 'USER_ADMIN', 'USER_OPR'],
      authorities: ['AUTH_ALGORITHM_READ']
    }
  },
  {
    path: '/device/algorithm/create',
    name: 'Tenant_Algorithm_Create',
    component: () => import('@/views/device/algorithm/edit/algorithm_create.vue'),
    hidden: true,
    meta: {
      title: 'AIアプリ新規登録',
      activeMenu: { path: '/device/algorithm/list', title: 'AIアプリ' },
      roles: ['SB_ADMIN'],
      authorities: ['AUTH_ALGORITHM_CREATE']
    }
  },
  {
    path: '/device/algorithm/version/create',
    name: 'Tenant_Algorithm_Version_Create',
    component: () => import('@/views/device/algorithm/version/version_create.vue'),
    hidden: true,
    meta: {
      title: 'バージョン作成',
      activeMenu: { path: '/device/algorithm/list', title: 'AIアプリ' },
      roles: ['SB_ADMIN'],
      authorities: ['AUTH_ALGORITHM_VERSION_CREATE']
    }
  },
  {
    path: '/device/algorithm/version/edit',
    name: 'Tenant_Algorithm_Version_Edit',
    component: () => import('@/views/device/algorithm/version/version_edit.vue'),
    hidden: true,
    meta: {
      title: 'バージョン編集',
      activeMenu: { path: '/device/algorithm/list', title: 'AIアプリ' },
      roles: ['SB_ADMIN'],
      authorities: ['AUTH_ALGORITHM_VERSION_DELETE']
    }
  },
  {
    path: '/device/algorithm/edit',
    name: 'Tenant_Algorithm_Edit',
    component: () => import('@/views/device/algorithm/edit/algorithm_edit.vue'),
    meta: {
      title: 'AIアプリ情報編集',
      activeMenu: { path: '/device/algorithm/list', title: 'AIアプリ' },
      roles: ['SB_ADMIN', 'USER_ADMIN'],
      authorities: ['AUTH_ALGORITHM_UPDATE']
    },
    hidden: true
  }
]

const event = [
  {
    path: '/device/event/list',
    name: 'Tenant_Event_List',
    component: () => import('@/views/device/event/main/event_main.vue'),
    meta: {
      title: 'イベント',
      icon: 'Notification',
      roles: ['SB_ADMIN', 'SB_OPR', 'USER_ADMIN', 'USER_OPR'],
      authorities: ['AUTH_EVENT_LIST']
    }
  },
  {
    path: '/device/event/show',
    name: 'Tenant_Event_Show',
    hidden: true,
    component: () => import('@/views/device/event/detail/event_show.vue'),
    meta: {
      title: ' イベント詳細',
      activeMenu: { path: '/device/event/list', title: 'イベント' },
      roles: ['SB_ADMIN', 'SB_OPR', 'USER_ADMIN', 'USER_OPR'],
      authorities: ['AUTH_EVENT_READ']
    }
  }
]

const deviceRouter = {
  path: '/device',
  component: Layout,
  name: 'Device',
  meta: { title: 'デバイス管理', svg: 'system' },
  children: [
    ...edgebox,
    ...camera,
    ...algorithm,
    ...event
  ]
}

export default deviceRouter
