<template>
  <el-upload
    :auto-upload="false"
    :multiple="false"
    :file-list="fileList"
    :accept="accept"
    :limit="1"
    :disabled="disabled"
    :on-exceed="handleExceed"
    :on-remove="handleRemove"
    :on-change="handleChange"
    style="width: 90%"
    drag
    action="#">
    <div class="el-upload__text" style="margin-top: 40px;text-align: center;line-height:22px">
      ドラッグ＆ドロップでファイルをアップロード<br>
      または
    </div>
    <el-button type="primary" size="default">ファイルを選択</el-button>
  </el-upload>
</template>

<script lang="ts">
import { useEmitter } from '@/common/directive/mixins/emitter'
import { Messages } from '@/utils/messages'
import { PropType, defineComponent, onMounted, ref } from 'vue'
import type { UploadFile } from 'element-plus'
import { ElButton } from 'element-plus'

export default defineComponent({
  name: 'FileUpload',
  props: {
    accept: {
      type: String,
      default: () => ''
    },
    fileList: {
      type: Array as PropType<UploadFile[]>,
      default: () => []
    },
    acceptType: {
      type: String,
      default: () => ''
    }
  },
  setup(props, { emit }) {
    const disabled = ref<boolean>(false)
    const tempIndex = ref<number>(0)
    const { dispatch } = useEmitter()

    onMounted(() => {
      if (props.fileList.length > 0) {
        props.fileList.map(item => {
          item.uid = item.uid || (Date.now() + tempIndex.value++)
          item.status = item.status || 'success'
          return item
        })
        const file = props.fileList[0]
        emit('update:modelValue', { 'raw': file.raw, 'update': file.status === 'ready' })
      }
    })

    const handleChange = (file: UploadFile, fileList: UploadFile[]) => {
      const _file = fileList[0]
      if (checkFile(_file.name)) {
        fileList.splice(0, 1)
        return false
      }
      emit('update:modelValue', { 'raw': _file.raw, 'update': _file.status === 'ready' })
    }

    const handleExceed = (files: File[], fileList: UploadFile[]) => {
      let postFiles = Array.prototype.slice.call(files)
      postFiles = postFiles.slice(0, 1).shift()

      const file = fileList.shift()
      if (checkFile(postFiles.name)) {
        fileList.push(file)
        return false
      }
      const filePost: UploadFile = {
        name: postFiles.name,
        size: postFiles.size,
        raw: postFiles,
        status: 'ready',
        uid: postFiles.lastModified,
      }
      fileList.push(filePost)
      const _file = fileList[0]
      emit('update:modelValue', { 'raw': _file.raw, 'update': _file.status === 'ready' })
      dispatch('ElFormItem', 'el.form.change', [_file])
    }

    const handleRemove = () => {
      emit('update:modelValue', { 'raw': null, 'update': props.fileList.length > 0 })
    }

    const checkFile = (name: string) => {
      const extension = name.indexOf('.') > -1
        ? `.${name.split('.').pop()}`
        : '';
      if (props.acceptType !== '' && extension !== props.acceptType) {
        const type = props.acceptType.substr(1).toUpperCase()
        Messages.error(Messages.SYS_FT_E0014(type), true)
        return true
      } else {
        return false
      }
    }

    return {
      disabled,
      handleChange,
      handleExceed,
      handleRemove
    }
  }
})
</script>

<style scoped>
.file-upload {
  width: 80%;
}
.el-upload {
  width: 100%;
}
.el-upload-dragger {
  width: 100%;
}
.el-upload-list__item-name {
  color: #333333;
}
.el-upload__text {
  color: #333333;
}
</style>
