<template>
  <DragDialog
    ref="dialog"
    :button-loading="state.loading"
    :button-bar-show="false"
    :ok-button-id="state.okButtonId"
    append-to-body title="テナント切り替え"
    style="width: 70%">
    <el-main class="switch-dialog-content" v-loading="state.loading">
      <SearchHeader id="tenantInputBox" v-model:search-loading="state.loading" :handle-search="handleSearch" max-length="60" placeholder="テナント" @keydown.tab="returnNextFocus"/>
      <el-table
        id="tenantTable"
        :data="state.tenantList"
        :search-function="handleSearch">
        <el-table-column prop="tenantId" label="テナントID" show-overflow-tooltip width="200px"/>
        <el-table-column prop="tenantName" label="テナント" show-overflow-tooltip/>
        <el-table-column width="260px" align="right">
          <template v-slot:default="scope">
            <el-tag v-if="scope.row.serviceStopFlag === '1'" type="info" effect="plain">サービス停止中</el-tag>
            <el-button
              :disabled="(scope.row.serviceStopFlag === '1' && !hasPermission(['AUTH_TENANT_STOPPING_SELECT'])) || scope.row.tenantId === store.getters.tenantId"
              :id="'tenantBtn'+scope.$index"
              type="primary"
              plain
              class="el-button__in-table"
              @keydown.tab="returnFirstFocus(scope.$index,$event)"
              @click="chooseTenant(scope.row)">選択</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
  </DragDialog>
</template>

<script lang="ts">
import { searchTenants, chooseTenant_api } from '@/api/tenant'
import { event } from '@/common/router/router_event'
import Cookies from 'js-cookie'
import { Utils } from '@/utils'
import { initVideoInterval } from '@/common/detection'
import { initLogInterval } from '@/common/log'
import { useRoute, useRouter } from 'vue-router'
import { defineComponent, reactive, ref } from 'vue'
import { hasPermission } from '@/utils/permission'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'TenantSwitchDialog',
  setup() {
    const dialog = ref(null)
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const state = reactive({
      isAllBtnDisable: true,
      okButtonId: '',
      loading: false,
      form: {
        selectTenantName: ''
      },
      tenantList: [],
      multipleSelection: []
    })

    const handleSearch = async (init, searchText) => {
      state.loading = true
      await searchTenants(searchText, init).then(res => {
        state.tenantList = res.value
      }).catch(() => {
        state.tenantList = []
      }).finally(() => {
        // dialog tab処理
        state.isAllBtnDisable = true
        for (let i = 0; i < state.tenantList.length; i++) {
          try {
            const btn = document.getElementById('tenantBtn' + i)
            if (btn instanceof HTMLButtonElement && !btn.disabled) {
              state.isAllBtnDisable = false
              state.okButtonId = 'tenantBtn' + i
            }
          } catch (error) {
          }
          
        }
        if (state.isAllBtnDisable === true) {
          state.okButtonId = 'tenantInputBox'
        }
        state.loading = false
      })
    }
    const open = async () => {
      dialog.value.open()
      await handleSearch(true, '')
    }
    const chooseTenant = async (row) => {
      state.loading = true
      sessionStorage.setItem('tenantId', '')
      await chooseTenant_api(row.tenantId).then(res => {
        Cookies.set('TEANANT_ID', row.tenantId)
        sessionStorage.setItem('tenantId', row.tenantId)
        store.dispatch('updateCurrentTenant', res.value).then((user) => {
          dialog.value.handleClose()
          if (route.fullPath === '/device/edgebox/list') {
            reloadEdgeBox()
          } else {
            router.push({ path: '/device/edgebox/list' })
          }
        }).catch(() => {
          dialog.value.handleClose()
        })

        store.dispatch('resetDetectionDownload').then(() => {
          const downloadList = Utils.getLocalStorage('detection').downloadList
          if (downloadList && downloadList.length > 0) {
            setTimeout(() => initVideoInterval(), 200)
          }
        })

        store.dispatch('resetLogDownload').then(() => {
          const logDownloadList = Utils.getLocalStorage('log').logDownloadList
          if (logDownloadList && logDownloadList.length > 0) {
            setTimeout(() => initLogInterval(), 200)
          }
        })
      }).catch(() => {
      }).finally(() => {
        state.loading = false
      })
    }
    const reloadEdgeBox = () => {
      event.emit('reloadEdgeBox')
    }
    const returnNextFocus = () => {
      if (state.isAllBtnDisable) {
        dialog.value.returnFirstFocus()
      }
    }
    const returnFirstFocus = (index, e) => {
      if (e.shiftKey) {
        return
      } else {
        let isAllNextBtnDisable = true
        if (index < state.tenantList.length - 1) {
          for (let i = index + 1; i < state.tenantList.length; i++) {
            const btn = document.getElementById('tenantBtn' + i)
            if (btn instanceof HTMLButtonElement && !btn.disabled) {
              isAllNextBtnDisable = false
            }
          }
          if (isAllNextBtnDisable) {
            dialog.value.returnFirstFocus()
          }
        }
        if (index + 1 === state.tenantList.length) {
          dialog.value.returnFirstFocus()
        }
      }
    }

    return {
      hasPermission,
      dialog,
      state,
      store,
      handleSearch,
      open,
      chooseTenant,
      reloadEdgeBox,
      returnNextFocus,
      returnFirstFocus
    }
  }
})
</script>

<style scoped>
.switch-dialog-content {
  max-height: 360px;
}
</style>