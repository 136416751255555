import Layout from '../../../layout/Layout.vue'

const tenant = [
  {
    path: '/tenant/account/list',
    name: 'User_Account_Main',
    component: () => import('@/views/account/user/main/user_account_main.vue'),
    meta: { title: 'テナントユーザー', svg: 'tenant-users', roles: ['SB_ADMIN', 'SB_OPR'], authorities: ['AUTH_USER_ACCOUNT_LIST'] }
  },
  {
    path: '/tenant/account/list',
    name: 'User_Account_Main',
    component: () => import('@/views/account/user/main/user_account_main.vue'),
    meta: { title: 'ユーザー', svg: 'tenant-users', roles: ['USER_ADMIN'], authorities: ['AUTH_USER_ACCOUNT_LIST'] }
  },
  {
    path: '/tenant/account/create',
    name: 'User_Account_Create',
    component: () => import('@/views/account/user/edit/user_account_create.vue'),
    hidden: true,
    meta: {
      title: 'テナントユーザー新規登録',
      activeMenu: { path: '/tenant/account/list', title: 'テナントユーザー' },
      roles: ['SB_ADMIN'],
      authorities: ['AUTH_USER_ACCOUNT_CREATE']
    }
  },
  {
    path: '/tenant/account/create',
    name: 'User_Account_Create',
    component: () => import('@/views/account/user/edit/user_account_create.vue'),
    hidden: true,
    meta: {
      title: 'ユーザー新規登録',
      activeMenu: { path: '/tenant/account/list', title: 'ユーザー' },
      roles: ['USER_ADMIN'],
      authorities: ['AUTH_USER_ACCOUNT_CREATE']
    }
  },
  {
    path: '/tenant/account/batch/create',
    name: 'User_Account_Batch_Create',
    component: () => import('@/views/account/user/edit/user_account_tenant_csv_batch_create.vue'),
    hidden: true,
    meta: {
      title: 'テナントユーザー一括登録',
      activeMenu: { path: '/tenant/account/list', title: 'テナントユーザー' },
      roles: ['SB_ADMIN', 'SB_OPR'],
      authorities: ['AUTH_USER_ACCOUNT_UPLOAD']
    }
  },
  {
    path: '/tenant/account/batch/create',
    name: 'User_Account_Batch_Create',
    component: () => import('@/views/account/user/edit/user_account_csv_batch_create.vue'),
    hidden: true,
    meta: {
      title: 'ユーザー一括登録',
      activeMenu: { path: '/tenant/account/list', title: 'ユーザー' },
      roles: ['USER_ADMIN', 'USER_OPR'],
      authorities: ['AUTH_USER_ACCOUNT_UPLOAD']
    }
  },
  {
    path: '/tenant/account/show',
    name: 'User_Account_Show_SB',
    component: () => import('@/views/account/user/detail/user_account_show.vue'),
    hidden: true,
    meta: {
      title: 'テナントユーザー詳細情報',
      activeMenu: { path: '/tenant/account/list', title: 'テナントユーザー' },
      roles: ['SB_ADMIN', 'SB_OPR'],
      authorities: ['AUTH_USER_ACCOUNT_READ']
    }
  },
  {
    path: '/tenant/account/show',
    name: 'User_Account_Show',
    component: () => import('@/views/account/user/detail/user_account_show.vue'),
    hidden: true,
    meta: {
      title: 'ユーザー詳細情報',
      activeMenu: { path: '/tenant/account/list', title: 'ユーザー' },
      roles: ['USER_ADMIN'],
      authorities: ['AUTH_USER_ACCOUNT_READ']
    }
  },
  {
    path: '/tenant/account/show/self',
    name: 'User_Account_Show_Self',
    component: () => import('@/views/account/user/detail/user_account_show.vue'),
    hidden: true,
    meta: {
      title: 'ユーザー詳細情報',
      activeMenu: { path: '/tenant/account/list', title: 'ユーザー' },
      roles: ['USER_ADMIN', 'USER_OPR'],
      authorities: ['AUTH_USER_ACCOUNT_READ']
    }
  },
  {
    path: '/tenant/account/edit',
    name: 'User_Account_Edit_SB',
    component: () => import('@/views/account/user/edit/user_account_edit.vue'),
    hidden: true,
    meta: {
      title: 'テナントユーザー情報編集',
      activeMenu: { path: '/tenant/account/list', title: 'テナントユーザー' },
      roles: ['SB_ADMIN'],
      authorities: ['AUTH_USER_ACCOUNT_UPDATE']
    }
  },
  {
    path: '/tenant/account/edit',
    name: 'User_Account_Edit',
    component: () => import('@/views/account/user/edit/user_account_edit.vue'),
    hidden: true,
    meta: {
      title: 'ユーザー情報編集',
      activeMenu: { path: '/tenant/account/list', title: 'ユーザー' },
      roles: ['USER_ADMIN'],
      authorities: ['AUTH_USER_ACCOUNT_UPDATE']
    }
  },
  {
    path: '/tenant/account/edit/self',
    name: 'User_Account_Edit_Self',
    component: () => import('@/views/account/user/edit/user_account_edit.vue'),
    hidden: true,
    meta: {
      title: 'ユーザー情報編集',
      activeMenu: { path: '/tenant/account/list', title: 'ユーザー' },
      roles: ['USER_ADMIN', 'USER_OPR'],
      authorities: ['AUTH_USER_ACCOUNT_UPDATE']
    }
  }
]

const tenantDetail = [
  {
    path: '/tenant/detail',
    name: 'Tenant_Detail',
    component: () => import('@/views/tenant/detail/tenant_detail.vue'),
    meta: { title: 'テナント詳細', svg: 'system', authorities: ['AUTH_TENANT_READ'] },
    hidden: true
  },
  {
    path: '/tenant/name/edit',
    name: 'Tenant_Name_Edit',
    component: () => import('@/views/tenant/edit/tenant_name_edit.vue'),
    meta: { title: 'テナント名編集', activeMenu: { path: '/tenant/detail', title: 'テナント詳細' }, authorities: ['AUTH_TENANT_NAME_UPDATE'] },
    hidden: true
  },
  {
    path: '/tenant/pfKey/edit',
    name: 'Notification_Url_Edit',
    component: () => import('@/views/tenant/edit/notification_url_edit.vue'),
    meta: { title: '通知先URL編集', activeMenu: { path: '/tenant/detail', title: 'テナント詳細' }, authorities: ['AUTH_TENANT_PF_URL_UPDATE'] },
    hidden: true
  }
]

const tenantRouter = {
  path: '/tenant',
  component: Layout,
  name: 'Tenant',
  meta: { title: 'テナント管理', svg: 'system', authorities: ['AUTH_TENANT_LIST'] },
  children: [
    ...tenant,
    ...tenantDetail
  ]
}

export default tenantRouter
