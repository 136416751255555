import LayoutNoMenu from '../../../layout/LayoutNoMenu.vue'

const tenantSwitch = [
  {
    path: '/tenant/list',
    name: 'Tenant_Switch_Main',
    component: () => import('@/views/tenant/main/tenant_main.vue'),
    meta: { title: 'テナント管理', svg: 'home', roles: ['SB_ADMIN', 'SB_OPR'], authorities: ['AUTH_TENANT_LIST'] }
  }
]

const tenantSwitchRouter = {
  path: '/tenant',
  component: LayoutNoMenu,
  name: 'TenantSwitch',
  meta: { title: 'テナント管理' },
  hidden: true,
  children: [
    ...tenantSwitch
  ]
}

export default tenantSwitchRouter
