import { store } from '@/common/store'

/**
 * @param {Array} value
 * @returns {Boolean}
 * @example see @/views/permission/directive.vue
 */
export function hasPermission(value) {
  if (value && value instanceof Array && value.length > 0) {
    const authorities = store.getters.authorities || []
    const permissionAuthorities = value

    const hasPermission = authorities.some(auth => {
      return permissionAuthorities.includes(auth)
    })

    if (!hasPermission) {
      return false
    }
    return true
  } else {
    console.log(`need Authorities! Like v-permission="['DEVLST_R','DEV_W']"`)
    return false
  }
}

// SB（PF管理）
export function isSbAdmin() {
  return store.getters.roleId === 'SB_ADMIN'
}
// SB（運用管理）
export function isSbOpr() {
  return store.getters.roleId === 'SB_OPR'
}
// 一般（管理者）
export function isUserAdmin() {
  return store.getters.roleId === 'USER_ADMIN'
}
// 一般（利用者）
export function isUserOpr() {
  return store.getters.roleId === 'USER_OPR'
}
// SBユーザー
export function isSbAccount() {
  return store.getters.roleId === 'SB_ADMIN' || store.getters.roleId === 'SB_OPR'
}
// 一般ユーザー
export function isUserAccount() {
  return store.getters.roleId === 'USER_ADMIN' || store.getters.roleId === 'USER_OPR'
}
