import { h } from 'vue'
import { ElMessage } from 'element-plus'

const messageInstances = []

export const Messages = {
  SYS_FT_E0001: '必須入力です。',
  SYS_FT_E0002: '有効なメールアドレスを入力してください。',
  SYS_FT_E0003: (param) => '{0}は「英字(大文字、小文字)、数字」をそれぞれ1文字以上使用し、半角でご入力ください。'.replace('{0}', param),
  SYS_FT_E0004: (param) => '{0}文字以内で入力してください。'.replace('{0}', param),
  SYS_FT_E0005: (param1, param2) => '{0}文字以上{1}文字以内で入力してください。'.replace('{0}', param1).replace('{1}', param2),
  SYS_FT_E0006: (param) => '{0}を選択してください。'.replace('{0}', param),
  SYS_FT_E0007: '入力内容が不正です。有効な文字は半角英数字、ハイフン、ドット、アンダースコアです。',
  SYS_FT_E0008: '有効なURLを入力してください。',
  SYS_FT_E0009: '有効な整数値を入力してください。',
  SYS_FT_E0010: 'この機能は利用できません。',
  SYS_FT_E0011: (param) => '{0}の入力フォーマットが不正です。'.replace('{0}', param),
  SYS_FT_E0012: '終了日時は開始日時以降の日時を選択して下さい。',
  SYS_FT_E0013: '無効なファイル名です。',
  SYS_FT_E0014: (param) => '{0}ファイルを選択してください。'.replace('{0}', param),
  SYS_FT_E0015: 'From以上の値を入力してください。',
  SYS_FT_E0016: (param) => '{0}は撮影日時の範囲内で選択してください。'.replace('{0}', param),
  SYS_FT_E0017: '１時間までの範囲で指定してください。',
  SYS_FT_E0018: (param1, param2) => '必須入力です。（{0}、{1}）'.replace('{0}', param1).replace('{1}', param2),
  SYS_FT_E0019: (param) => 'ファイル名は{0}文字以内にしてください。'.replace('{0}', param),
  SYS_FT_E0020: '入力内容が不正です。有効な文字は半角英数字です。',

  TNT_FT_I0001: 'PFキーを変更しました！',
  TNT_FT_I0002: 'PFキーを有効化しました！',
  TNT_FT_I0003: 'PFキーを無効化しました。',

  TNT_FT_I0004: '編集内容を保存しました！',
  TNT_FT_I0005: 'サービスを再開しました！',
  TNT_FT_I0006: 'サービスを停止しました。',

  TNT_FT_I0007: 'テナントを作成しました！',
  TNT_FT_I0008: 'テナントを削除しました。',

  ACN_FT_I0001: '仮登録メールを送信しました！',
  ACN_FT_I0002: 'ユーザーを新規登録しました！',
  ACN_FT_E0003: 'ユーザーを選択してください。',
  ACN_FT_I0003: 'パスワードをリセットしました！',
  ACN_FT_I0004: 'パスワードを設定しました！',
  ACN_FT_E0004: (param) => '＠より前の部分は{0}文字以内で入力してください。'.replace('{0}', param),

  HLP_FT_I0001: 'ヘルプを新規登録しました！',
  HLP_FT_E0001: 'ヘルプファイルのアップロードに失敗しました。もう一度ファイルを選択して、アップロードしてください。',
  HLP_FT_I0002: 'ヘルプを削除しました。',

  ALG_FT_I0001: 'AIアプリマスタの登録を開始しました！',
  ALG_FT_I0002: 'AIアプリマスタを削除しました。',
  ALG_FT_I0003: 'AIアプリを変更しました！',
  ALG_FT_I0004: 'バージョンを削除しました。',
  ALG_FT_I0005: 'バージョンの作成を開始しました！',
  ALG_FT_I0006: '任意メソッドの実行をリクエストしました！',
  ALG_FT_I0007: '紐付け対象テナントを追加しました！',
  ALG_FT_I0008: '紐付け対象テナントを解除しました！',
  ALG_FT_E0001: 'AIアプリファイルのアップロードに失敗しました。もう一度ファイルを選択して、アップロードしてください。',
  ALG_FT_E0002: '紐付け対象テナントが存在するため削除できません。\n削除する場合は、紐付け対象テナントを全て解除してください。',

  DVS_FT_E0001: '再起動するエッジボックスを選択してください。',
  DVS_FT_E0002: (param) => '再起動には{0}台以下のエッジボックスを選択してください。'.replace('{0}', param),
  DVS_FT_E0003: (param1, param2) => '以下エッジボックスのダウンロードが失敗しました。\n{0}  {1}'.replace('{0}', param1).replace('{1}', param2),
  DVS_FT_E0004: (param1) => '以下映像のダウンロードが失敗しました。\n{0}'.replace('{0}', param1),
  DVS_FT_I0001: 'エッジボックスを削除しました。',
  DVS_FT_I0002: (param1, param2) => 'エッジボックス（{0}）の{1}のログダウンロードの準備ができました。\nログダウンロードを開始します。'.replace('{0}', param1).replace('{1}', param2),
  DVS_FT_I0003: (param1, param2) => 'エッジボックス（{0}）の{1}は存在しません。'.replace('{0}', param1).replace('{1}', param2),
  DVS_FT_I0004: '推論結果ダウンロードの準備ができました。\nダウンロードを開始します。',
  DVS_FT_I0005: 'エッジボックス最新状態の取得を開始しました！',
  DVS_FT_I0006: '映像取得の準備ができました。\n映像のダウンロードを開始します。',
  DVS_FT_E0006: 'ファームウェアアップデートするエッジボックスを選択してください。',
  DVS_FT_E0007: (param) => 'ファームウェアアップデートには{0}台以下のエッジボックスを選択してください。'.replace('{0}', param),
  DVS_FT_E0008: '実施曜日と実施時間は両方入力してください。',
  DVS_FT_E0009: (param1) => '以下画像のダウンロードが失敗しました。\n{0}'.replace('{0}', param1),
  DVS_FT_I0007: '画像取得の準備ができました。\n画像のダウンロードを開始します。',
  DVS_FT_I0008: '推論結果は存在しません。',
  DVS_FT_E0010: '入力値が不正な形式です',

  MTC_FT_I0001: 'お知らせを新規登録しました！',
  MTC_FT_I0002: 'お知らせを削除しました。',

  DVM_FT_I0001: 'エッジボックスモデルを新規登録しました！',
  DVM_FT_I0002: '管理モジュールの登録を開始しました！',
  DVM_FT_E0001: '管理モジュールのアップロードに失敗しました。もう一度ファイルを選択して、アップロードしてください。',
  DVM_FT_I0003: '管理モジュールを削除しました。',
  DVM_FT_I0004: 'エッジボックスモデルを削除しました。',
  DVM_FT_I0005: 'バージョンの作成を開始しました！',

  TAG_FT_I0001: 'タグ名を保存しました！',
  TAG_FT_I0002: 'タグ名を削除しました',
  TAG_FT_I0003: 'タグを新規登録しました！',

  successHasMsgFromBackend(texts) {
    const msg = this.getVnMsg(h, texts)
    const message = ElMessage.success({
      message: msg,
      duration: 5000,
      dangerouslyUseHTMLString: false,
      showClose: true
    })
    this.cached(message, true)
    return message
  },
  errorHasMsgFromBackend(texts) {
    const msg = this.getVnMsg(h, texts)
    const message = ElMessage.error({
      message: msg,
      duration: 0,
      dangerouslyUseHTMLString: false,
      showClose: true
    })
    this.cached(message, true)
    return message
  },
  success(text, keep) {
    const message = ElMessage.success({
      message: text,
      duration: 5000,
      dangerouslyUseHTMLString: false,
      showClose: true
    })
    this.cached(message, keep)
    return message
  },
  error(text, keep) {
    const message = ElMessage.error({
      message: text,
      duration: 0,
      dangerouslyUseHTMLString: false,
      showClose: true
    })
    this.cached(message, keep)
    return message
  },
  message(option) {
    this.cached(ElMessage(option))
  },
  cached(message, keep) {
    if (keep !== true) {
      messageInstances.push(message)
    }
  },
  getVnMsg(h, texts) {
    const msgArr = []
    for (let i = 0; i < texts.length; i++) {
      msgArr[i] = h('p', { class: 'el-message__content' }, texts[i])
    }
    return h('p', null, msgArr)
  },
  closeAll() {
    for (let i = messageInstances.length - 1; i >= 0; i--) {
      messageInstances[i] && messageInstances[i].close()
    }
    messageInstances.length = 0
  }
}
