<template>
  <div class="info-bar">
    <el-icon>
      <InfoFilled/>
    </el-icon>
    <span>{{ text }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true
    }
  }
})
</script>

<style scoped>
.info-bar {
  background-color: #D9EBF6;
  border-radius: 4px;
  font-size: 16px;
  padding: 16px;
  line-height: 16px;
  color: #333333;
  align-items: center;
  display: flex;
  gap: 12px;
}
</style>
