<template>
  <el-header class="search-header">
    <el-form ref="searchForm" :inline="true" :model="form" :rules="rules" @submit.prevent>
      <el-form-item size="default" prop="searchText">
        <el-input ref="searchTextInput" v-model="form.searchText" :placeholder="placeholder" :maxlength="maxLength" :style="{ width: width }" @blur="search" @keyup.enter="search">
          <template v-slot:suffix>
            <el-icon class="search" :loading="searchLoading" @click="handleIconClick">
              <Search />
            </el-icon>
          </template>
        </el-input>
      </el-form-item>
    </el-form>
  </el-header>
</template>

<script lang="ts">
import { nextTick } from '@vue/runtime-dom'
import { defineComponent, reactive, ref } from 'vue'

export default defineComponent({
  name: 'SearchHeader',
  props: {
    searchLoading: {
      type: Boolean,
      required: true
    },
    maxLength: {
      type: String,
      default: () => '64'
    },
    placeholder: {
      type: String,
      default: () => ''
    },
    handleSearch: {
      type: Function,
      required: true
    },
    trimFlag: {
      type: Boolean,
      default: () => false
    },
    rules: {
      type: Object,
      default: () => {}
    },
    width: {
      type: String,
      default: () => '400px'
    }
  },
  setup(props) {
    const form = reactive({
      searchText: ''
    })
    const searchForm = ref(null)
    const searchTextInput = ref(null)

    const search = async () => {
      if (props.trimFlag && form.searchText) {
        form.searchText = form.searchText.trim()
      }
      await searchForm.value.validate((valid: boolean) => {
        if (valid) {
          props.handleSearch(false, form.searchText)
          searchTextInput.value.blur()
        }
      })
    }

    const handleIconClick = (event: Event) => {
      event.stopPropagation()
      search()
    }

    return {
      searchForm,
      searchTextInput,
      form,
      search,
      handleIconClick
    }
  }
})
</script>

<style rel="stylesheet/css" lang="css" scoped>
  .search-header {
    min-height: 50px;
    display: block;
    padding: 0 !important;
    margin: 0;
    text-align: right;
  }
  .el-form-item {
    margin-right: 0 !important;
  }
</style>
