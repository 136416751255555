<template>
  <el-tabs :model-value="activeName" :lazy="true" class="multi_panel_dev" tab-position="right">
    <el-tab-pane
      v-for="(panel) in panels"
      :key="panel.name"
      :label="tabName"
      :name="panel.name"
    >
      <component :is="panel.name" :key="panel.name + keyIndent[panel.name]" :ref="setRef(panel.name)"/>
    </el-tab-pane>
  </el-tabs>
</template>

<script lang="ts">
import { Panel } from '@/types'
import { DefineComponent, defineComponent, nextTick, onMounted, provide, ref, watch } from 'vue'

export default defineComponent({
  name: 'MultiPanel',
  props: {
    panels: {
      type: Array as () => Panel[],
      default: function() {
        return []
      }
    },
    headTitleWidth: {
      type: Number,
      default: () => 90
    }
  },
  setup(props) {
    const activeName = ref<string>('')
    const tabName = ref<string>('')
    const keyIndent = ref<Record<string, number>>({})
    const title = ref<string>('')
    const oprType = ref<string>('')
    const panelReCreate = ref<Record<string, boolean>>({})
    const refs = ref<{ [key: string]: any }>({})
    
    watch(activeName, (panelName) => {
      const panel = props.panels.find(e => e.name === panelName)
      if (panel) {
        if (typeof panel.title === 'string') {
          title.value = panel.title
        } else {
          title.value = panel.title[oprType.value]
        }
      }
    })

    const loadPanels = async () => {
      const components = import.meta.glob('/src/views/**/*.vue')
      for (const p of props.panels) {
        const path = `/src/views/${p.vuePath}.vue`
        if (components[path]) {
          // 非同期インポートを解決
          const component = (await components[path]()) as { default: DefineComponent }
          // グローバルコンポーネントとして登録
          window.app.component(p.name, component.default)
        }
        keyIndent.value[p.name] = 0
        panelReCreate.value[p.name] = p.reCreate !== undefined ? p.reCreate : true
      }
      activeName.value = props.panels[0].name
    }

    const setRef = (name: string) => (el: any) =>{
      refs.value[name] = el
    }

    const toPanel = (panelName: string, ...param: any[]) => {
      let name: string = ''
      if (panelName.includes('/')) {
        [name, oprType.value] = panelName.split('/')
      } else {
        name = panelName
        oprType.value = ''
      }

      if (panelReCreate.value[name]) {
        keyIndent.value[name] = (keyIndent.value[name] || 0) + 1
      }

      activeName.value = name

      nextTick(() => {
        const panelRef = refs.value[name]
        if (panelRef?.initPanel) {
          panelRef.initPanel(...param)
        }
      })

      return Promise.resolve()
    }

    const goBack = () => {
      const activePanel = refs.value[activeName.value]
      if (activePanel[0].goBack) {
        activePanel[0].goBack()
      }
    }

    provide('toPanel', toPanel)
    loadPanels()

    return {
      activeName,
      tabName,
      keyIndent,
      title,
      oprType,
      panelReCreate,
      setRef,
      toPanel,
      goBack
    }
  }
})
</script>

<style rel="stylesheet/scss" lang="scss">
  .multi_panel_dev {
    .el-tabs__header .is-right {
      padding: 0;
      position: relative;
      margin: 0 0 15px;
      visibility: hidden;
    }
  }
</style>
