<template>
  <div style="width: 100%;">
    <slot v-if="valid"/>
  </div>
</template>

<script lang="ts">
import request from '@/common/request'
import { defineComponent, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
export function tokenValid_api(action, token) {
  return request({
    url: action + '?token=' + token,
    method: 'get'
  })
}

export default defineComponent({
  name: 'TokenValid',
  props: {
    tokenValidAction: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const valid = ref<boolean>(false)
    const route = useRoute()
    const router = useRouter()

    const token = route.query.token || ''
    tokenValid_api(props.tokenValidAction, token).then(res => {
      valid.value = true
    }).catch(e => {
      router.push('/404')
    })

    return { valid }

  }
})
</script>
