<template>
  <el-button :type="type" :icon="icon" :circle="circle" :plain="plain" :autofocus="autofocus" :disabled="disabled" :loading="loading || innerLoading" :size="size" @click="handleLoading">
    <span v-if="$slots.default && !(loadingNoText && (loading || innerLoading))">
      <slot/>
    </span>
  </el-button>
</template>

<script lang="ts">
import { PropType, defineComponent, ref, watch } from 'vue'

export default defineComponent({
  name: 'LoadingButton',
  props: {
    type: {
      type: String as PropType<"default" | "primary" | "text" | "success" | "warning" | "info" | "danger">,
      default: function() {
        return 'primary'
      }
    },
    icon: {
      type: String,
      default: function() {
        return ''
      }
    },
    disabled: {
      type: Boolean,
      default: function() {
        return false
      }
    },
    circle: {
      type: Boolean,
      default: function() {
        return false
      }
    },
    size: {
      type: String as PropType<"" | "default" | "small" | "large" >,
      default: function() {
        return ''
      }
    },
    autofocus: {
      type: Boolean,
      default: function() {
        return true
      }
    },
    plain: {
      type: Boolean,
      default: function() {
        return false
      }
    },
    loadingNoText: {
      type: Boolean,
      default: function() {
        return false
      }
    },
    // ボタン押下時、非同期処理を行う際には指定する
    loading: {
      type: Boolean,
      default: function() {
        return false
      }
    }
  },
  setup(props) {
    const innerLoading = ref<boolean>(false)

    watch(() => props.loading, (newValue) => {
      if (newValue === false) {
        innerLoading.value = false
      }
    })

    const handleLoading = () => {
      innerLoading.value = true
      setTimeout(() => {
        innerLoading.value = false
      }, 300)
    }

    return { innerLoading, handleLoading }
  }
})
</script>
