import {
  login_api,
  login_sendmail_api,
  logout_api,
  healthCheck_api,
  request_auth_api,
  login_reSendmail_api
} from '@/api/login'
import { event } from '@/common/router/router_event'
import Cookies from 'js-cookie'
import { isUserAccount } from '@/utils/permission'

const auth = {
  state: {
    user: {},
    roleId: '',
    authorities: []
  },

  mutations: {
    SET_USER_INFO: (state, user) => {
      const { userId, userName, userEmail, lastLoginAt, tenantId, tenantName, roleId, authorities } = user
      state.user = { userId, userName, userEmail, lastLoginAt, tenantId, tenantName }
      state.roleId = roleId
      state.authorities = authorities || []
    },
    UPDATE_TENANT: (state, tenant) => {
      if (state.user) {
        state.user.tenantId = tenant.tenantId
        state.user.tenantName = tenant.tenantName
        state.roleId = tenant.roleId
        state.authorities = tenant.authorities
        event.emit('resetRouter')
      }
    }
  },

  actions: {
    login({ commit }, userInfo) {
      const username = userInfo.username.trim()
      return new Promise((resolve, reject) => {
        login_api(username, userInfo.password, userInfo.onetimePassword, userInfo.uuid).then(response => {
          const { sessionId, userId, userName, userEmail, lastLoginAt, tenantId, tenantName, roleId, authorities, versionId } = response.value
          commit('SET_USER_INFO', { userId, userName, userEmail, lastLoginAt, tenantId, tenantName, roleId, authorities })
          Cookies.set('VMSANALYSIS_LOGIN_TOKEN', sessionId)
          Cookies.set('TEANANT_ID', tenantId)
          window.sessionStorage.setItem('token', sessionId)
          window.sessionStorage.setItem('tenantId', tenantId)
          window.sessionStorage.setItem('versionId', versionId)
          event.emit('resetRouter')
          event.emit('resetDetectionDownload')
          if (isUserAccount()) {
            event.emit('resetLogDownload')
          }
          resolve(userId)
        }).catch(error => {
          reject(error)
        })
      })
    },

    loginSendMail({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login_sendmail_api(userInfo.username, userInfo.password, userInfo.uuid).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    loginReSendMail({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login_reSendmail_api(userInfo.username, userInfo.uuid).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    updateCurrentTenant({ commit }, tenant) {
      commit('UPDATE_TENANT', tenant)
    },

    updateCurrentUser({ commit }, user) {
      commit('SET_USER_INFO', user)
    },

    healthCheck({ commit }, toLogin) {
      return new Promise((resolve, reject) => {
        healthCheck_api(toLogin).then(response => {
          const versionId = response.value.versionId
          if (versionId !== window.sessionStorage.getItem('versionId')) {
            window.sessionStorage.setItem('versionId', versionId)
            window.isNeedRefreshFlag = true // バージョンアップがあるので、画面refresh必要
          }
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    logOut({ commit }) { // logout
      return new Promise((resolve, reject) => {
        logout_api().then(() => {
          Cookies.remove('VMSANALYSIS_LOGIN_TOKEN')
          Cookies.remove('TEANANT_ID')
          window.sessionStorage.removeItem('token')
          window.sessionStorage.removeItem('tenantId')
          window.sessionStorage.removeItem('versionId')
          event.emit('clearAllDetectionDownload')
          event.emit('clearAllLogDownload')
          commit('SET_USER_INFO', {})
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    fedLogOut({ commit }) { // session time out
      return new Promise(resolve => {
        commit('SET_USER_INFO', {})
        Cookies.remove('VMSANALYSIS_LOGIN_TOKEN')
        Cookies.remove('TEANANT_ID')
        window.sessionStorage.removeItem('token')
        window.sessionStorage.removeItem('tenantId')
        window.sessionStorage.removeItem('versionId')
        event.emit('clearAllDetectionDownload')
        event.emit('clearAllLogDownload')
        resolve()
      })
    },

    clearStorage({ commit }) {
      commit('SET_USER_INFO', { userId: '', userName: '', userEmail: '', lastLoginAt: '', tenantId: '', tenantName: '', roleId: '', authorities: [] })
      event.emit('resetRouter')
    },

    refreshAuth({ commit }) {
      return new Promise((resolve, reject) => {
        request_auth_api().then((response) => {
          const versionId = response.value.versionId
          if (versionId) {
            sessionStorage.setItem('versionId', versionId)
            window.isNeedRefreshFlag = false // 二回refreshを防ぐため
          }
          const { userId, userName, userEmail, lastLoginAt, tenantId, tenantName, roleId, authorities } = response.value
          commit('SET_USER_INFO', { userId, userName, userEmail, lastLoginAt, tenantId, tenantName, roleId, authorities })
          event.emit('resetRouter')
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default auth
