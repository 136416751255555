<template>
  <section class="app-main">
    <router-view v-slot="{ Component }">
      <transition name="fade-transform" mode="out-in">
        <keep-alive :max="1" :include="cachedView">
          <component :is="Component" />
        </keep-alive>
      </transition>
    </router-view>
  </section>
</template>

<script lang="ts">
import { onMounted } from '@vue/runtime-dom'
import { defineComponent, Ref, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'AppMain',
  setup() {
    const cachedView: Ref<string> = ref('')
    const route = useRoute()

    watch(route, (to, from) => {
      if (!to.meta.activeMenu && to.matched.length > 1) {
        cachedView.value = to.matched[1].components.default.name
      }
    })

    // watchは画面遷移でしか動かないので、リロード時にはonMountedでcachedViewを更新する
    const initializeCachedView = () => {
      if (!route.meta.activeMenu && route.matched.length > 1) {
        cachedView.value = route.matched[1].components.default.name
      }
    }

    onMounted(() => {
      initializeCachedView()
    })

    return {
      cachedView
    }
  }
})
</script>

<style scoped>
.app-main {
  /*50 = navbar  */
  padding-top: 50px;
  min-height: calc(100vh - 50px);
  position: relative;
  overflow: hidden;
}
</style>
