<template>
  <router-link :to="to" :target="target">
    <slot/>
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    to: {
      type: String,
      required: true
    },
    target: {
      type: String,
      default: ''
    }
  }
})
</script>
