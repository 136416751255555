import request from '@/common/request'
import Qs from 'qs'

export function login_sendmail_api(username, password, uuid) {
  const param = {
    'username': username.toLowerCase(),
    'password': password,
    'uuid': uuid
  }
  return request({
    url: '/otp-sendmail',
    method: 'post',
    data: Qs.stringify(param)
  })
}
export function login_reSendmail_api(username, uuid) {
  const param = {
    'username': username.toLowerCase(),
    'uuid': uuid
  }
  return request({
    url: '/otp-resendmail',
    method: 'post',
    data: Qs.stringify(param)
  })
}

export function login_api(username, password, oneTimePassword, uuid) {
  const param = {
    'username': username.toLowerCase(),
    'password': password,
    'onetimePassword': oneTimePassword,
    'uuid': uuid
  }
  return request({
    url: '/auth/login',
    method: 'post',
    data: Qs.stringify(param)
  })
}

export function healthCheck_api(toLogin) {
  return request({
    url: `/session/check?toLogin=${toLogin}`,
    method: 'get',
    mode: 'silent'
  })
}

export function logout_api() {
  return request({
    url: '/auth/logout',
    method: 'post'
  })
}

export function request_auth_api() {
  return request({
    url: '/request/auth',
    method: 'get'
  })
}
