<template>
  <el-card :body-style="{ padding: '16px', height: '100%', width: '100%' }" shadow="never" class="coords-card">
    <div class="coords-card-header">
      <div class="coords-card-title">
          {{ cardTitle }}
        <el-link v-if="cardTitle === '矩形2'" class="coords-card-textlink" @click="deleteAdditionalPolygon">
          削除
        </el-link>
      </div>
      <el-link class="coords-card-textlink" @click="copyJsonCoords">
        座標をコピー
      </el-link>
    </div>
    <el-row class="coords-card-content" type="flex" justify="space-between">
      <div class="coords-table-area">
        <slot/>
      </div>
      <div class="buttons">
        <div class="button-area">
          <el-tooltip :disabled="!isOutputHovered" effect="dark" content="座標を出力" placement="top">
            <span class="coords-control-buttons" @mouseenter="isOutputHovered = true" @mouseleave="isOutputHovered = false">
              <svg-icon
                v-show="!isOutputHovered"
                :class="{ hover: isOutputHovered }"
                icon-class="icon_Coordinate_output"
                class="coords-control-button"
                font-size="24"
                style="width: 1.375em"/>
              <svg-icon
                v-show="isOutputHovered"
                :class="{ hover: isOutputHovered }"
                icon-class="icon_Coordinate_output_hover"
                class="coords-control-button"
                font-size="24"
                style="width: 1.375em"
                @click="outputCoords"/>
            </span>
          </el-tooltip>
          <el-tooltip :disabled="!isReflectHovered" effect="dark" content="テキスト入力した座標を反映" placement="top">
            <span class="coords-control-buttons" @mouseenter="isReflectHovered = true" @mouseleave="isReflectHovered = false">
              <svg-icon
                v-show="!isReflectHovered"
                :class="{ hover: isReflectHovered }"
                icon-class="icon_Coordinate_reflection"
                class="coords-control-button"
                font-size="24"
                style="width: 1.375em"/>
              <svg-icon
                v-show="isReflectHovered"
                :class="{ hover: isReflectHovered }"
                icon-class="icon_Coordinate_reflection_hover"
                class="coords-control-button"
                font-size="24"
                style="width: 1.375em"
                @click="reflectCoords"/>
            </span>
          </el-tooltip>
        </div>
      </div>
      <div>
        <textarea v-model="localJsonCoords" class="json-coords-area" type="textarea"/>
      </div>
    </el-row>
    <div v-if="absoluteCoord" class="normalrect-overlay"/>
  </el-card>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  name: 'CoordsCard',
  props: {
    absoluteCoord: {
      type: Boolean,
      required: false,
      default: false
    },
    cardTitle: {
      type: String,
      required: true
    },
    coords: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const isOutputHovered = ref<boolean>(false)
    const isReflectHovered = ref<boolean>(false)
    const localJsonCoords = ref<string>(props.coords)

    watch(() => props.coords, (newValue) => {
      localJsonCoords.value = newValue
    })

    watch(localJsonCoords, (newValue) => {
      emit('input', newValue)
    })

    const deleteAdditionalPolygon = () => {
      emit('delete-additional-polygon')
    }

    const outputCoords = () => {
      localJsonCoords.value = props.coords
      emit('output-coords', props.cardTitle)
    }

    const reflectCoords = () => {
      emit('reflect-coords', props.cardTitle, localJsonCoords.value)
    }

    const copyJsonCoords = async () => {
      await navigator.clipboard.writeText(localJsonCoords.value)
    }

    return {
      isOutputHovered,
      isReflectHovered,
      localJsonCoords,
      deleteAdditionalPolygon,
      outputCoords,
      reflectCoords,
      copyJsonCoords
    }
  }
})
</script>

<style scoped>
  .coords-card {
    width: 520px;
    display: flex;
    border-radius: 0;
    margin-bottom: 8px;
    position: relative;
    flex-wrap: nowrap;
  }
  .coords-card-header {
    line-height: 14px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    height: 14px;
    margin-bottom: 12px;
  }
  .coords-card-title {
    display: flex;
    align-items: center;
    height: 100%;
    line-height: 14px;
    font-size: 14px;
    color: #333333;
    font-weight: 600;
  }
  .coords-card-textlink {
    line-height: 12px;
    font-size: 12px !important;
    margin-left: 8px;

    &:hover {
      font-size: 12px !important;
      text-decoration: none !important;
    }
  }
  .coords-card-content {
    display: flex;
    flex-wrap: nowrap;
  }
  .coords-table-area {
    width: 180px;
    height: auto;
  }
  .buttons {
    width: fit-content;
    vertical-align: middle;
    padding: 0 32px 0;
    display: flex;
    align-items: center;
  }
  .button-area {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 4px;
    margin-bottom: 8px;
  }
  .coords-control-buttons {
    height: 24px;
  }
  .coords-control-button {
    height: 24px;
    cursor: pointer;
  }
  .json-coords-area {
    height: 100%;
    resize: none;
    font-size: 12px;
    border-radius: 4px;
    width: 100%;
    padding: 6px;
    line-height: 1.35em;
    border-color: #949494;
  }
  .normalrect-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.7);
  }
</style>
