import {
  edgeBoxLogsDeviceDownloadUrl,
  edgeBoxLogsApplicationDownloadUrl,
  downloadDetection_api
} from '@/api/device/edgebox'
import { Messages } from '@/utils/messages'
import { Utils } from '@/utils'
import { event } from '@/common/router/router_event'
import JsonConfig from '#/config.json'
import { store } from '@/common/store'

export function initLogInterval() {
  const edgeboxLogIntervalConfig = JsonConfig.edgeboxLogInterval
  let logIntervalId = store.getters.logIntervalId
  if (logIntervalId) {
    clearInterval(logIntervalId.value)
    store.dispatch('clearLogIntervalId')
  }
  logIntervalId = setInterval(handleLogDetection, edgeboxLogIntervalConfig)
  store.dispatch('setLogIntervalId', logIntervalId)
}

export function handleLogDetection() {
  let logIntervalId = store.getters.logIntervalId
  const logDownloadList = Utils.getLocalStorage('log').logDownloadList
  if (logDownloadList && logDownloadList.length > 0) {
    downloadDetection_api(logDownloadList).then(res => {
      const complete = res.value.success
      if (complete && complete.length > 0) {
        handleCompleteDetection(complete)
      }
      const error = res.value.error
      if (error && error.length > 0) {
        handleErrorDetection(error)
      }
    }).catch(e => {
    })
  } else {
    clearInterval(logIntervalId)
    logIntervalId = null
    store.dispatch('clearLogIntervalId')
  }
  Utils.updateLogTime(new Date().getTime()) // update time
}

export function handleLogDownload(urls) {
  urls.forEach(url => {
    const iframe = document.createElement('iframe')
    iframe.style.display = 'none'
    iframe.style.height = 0
    iframe.src = url
    document.body.appendChild(iframe)
    setTimeout(() => {
      iframe.remove()
    }, 5 * 60 * 1000)
  })
}

export function handleCompleteDetection(complete) {
  complete.forEach(item => {
    setTimeout(() => {
      if (item.requestKind === '2') {
        edgeBoxLogsDeviceDownloadUrl(item.edgeBoxId, item.requestId, item.edgeBoxName, '6か月').then(deviceResponse => {
          const urls = deviceResponse.value
          if (typeof (urls) !== 'undefined') {
            if (urls.length <= 0) {
              const texts = Messages.DVS_FT_I0003(item.edgeBoxName, 'デバイスログ').split('\n')
              Messages.successHasMsgFromBackend(texts)
            } else {
              const texts = Messages.DVS_FT_I0002(item.edgeBoxName, 'デバイスログ').split('\n')
              Messages.successHasMsgFromBackend(texts)
              handleLogDownload(urls)
            }
          }
        }).catch(e => {
        })
      }
      if (item.requestKind === '3') {
        edgeBoxLogsApplicationDownloadUrl(item.edgeBoxId, item.requestId, item.edgeBoxName, '6か月').then(applicationResponse => {
          const urls = applicationResponse.value
          if (typeof (urls) !== 'undefined') {
            if (urls.length <= 0) {
              const texts = Messages.DVS_FT_I0003(item.edgeBoxName, 'アプリログ').split('\n')
              Messages.successHasMsgFromBackend(texts)
            } else {
              const texts = Messages.DVS_FT_I0002(item.edgeBoxName, 'アプリログ').split('\n')
              Messages.successHasMsgFromBackend(texts)
              handleLogDownload(urls)
            }
          }
        }).catch(e => {
        })
      }
    }, 200)
  })
  downloadLogSplice(complete) // delete
  event.emit('logDownLoadList', complete)
}

export function handleErrorDetection(error) {
  error.forEach(item => {
    setTimeout(() => {
      if (item.requestKind === '2') {
        const texts = Messages.DVS_FT_E0003(item.edgeBoxName, 'デバイスログ').split('\n')
        Messages.errorHasMsgFromBackend(texts)
      } else if (item.requestKind === '3') {
        const texts = Messages.DVS_FT_E0003(item.edgeBoxName, 'アプリログ').split('\n')
        Messages.errorHasMsgFromBackend(texts)
      }
    }, 200)
  })
  downloadLogSplice(error) // delete
  event.emit('logDownLoadList', error)
}

export function downloadLogSplice(complete) {
  const log = Utils.getLocalStorage('log')
  const logDownloadList = log.logDownloadList || []
  const indexs = []
  complete.forEach(d => {
    logDownloadList.forEach((dd, index) => {
      if (String(d.requestId) === String(dd)) {
        indexs.push(index)
      }
    })
  })
  if (indexs.length > 0) {
    indexs.reverse().forEach(index => {
      logDownloadList.splice(index, 1)
    })
  }
  Utils.saveLocalStorage('log', log)
}
