<template>
  <div :style="{'backgroundColor': 'var(--sidebar-tenat-bg)'}" :class="{'tenant-sidebar_collapse':!isCollapse}" class="sidebar-tenant-container">
    <div v-show="isCollapse" class="tenant-text tenant-title">
      <span>テナント</span>
    </div>
    <el-tooltip :disabled="disabled" :content="tenantName" placement="top">
      <div v-show="isCollapse" class="tenant-text tenant-name">
        <span>{{ tenantName }}</span>
      </div>
    </el-tooltip>
    <div class="tenant-text tenant-button">
      <el-dropdown class="sidebar-dropdown-button" @command="handleOperation">
        <el-button :class="{'tenant-button_collapse':!isCollapse.value}" icon="MoreFilled" class="tenant-el-button" />
        <template #dropdown>
          <!-- 以降、Element内部クラスのoverrideはinline styleでしか効かないためタグ内に記載 -->
          <el-dropdown-menu style="
            border-radius: 0% !important;
            border: 1px solid #333333 !important;
            box-shadow: none !important;">
            <el-dropdown-item id="tenantDetail" command="detail" style="
              padding: 14px 24px !important;
              font-size: 14px !important;
              color: #333333 !important;
              ">テナント詳細</el-dropdown-item>
            <el-dropdown-item id="tenantSwitch" command="switch" style="
              padding: 14px 24px !important;
              font-size: 14px !important;
              color: #333333 !important;
               border-top: 1px solid #333333;
              ">テナント切り替え</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <TenantSwitchDialog ref="tenantSwitchDialogRef"/>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import TenantSwitchDialog from '@/views/tenant/switch/tenant_switch_dialog.vue'

export default defineComponent({
  components: { TenantSwitchDialog },
  setup() {
    const store = useStore()
    const router = useRouter()
    const tenantSwitchDialogRef = ref(null)

    const tenantName = computed(() => store.getters.tenantName)
    const isCollapse = computed(() => store.getters.sidebar.opened)
    const disabled = ref(true)

    watch(tenantName, (newName) => {
      isOverFlow(newName)
    })

    onMounted(() => {
      isOverFlow(tenantName.value)
    })

    const handleOperation = (command: string) => {
      if (command === 'switch') {
        tenantSwitchDialogRef.value?.open()
      } else if (command === 'detail') {
        router.push('/tenant/detail')
      }
    }
    const isOverFlow = (name: string) => {
      const str = escape(name)
      let d = 0
      for (var i = 0, length = 0; i < str.length; i++, length++) {
        if (str.charAt(i) === '%') {
          if (str.charAt(++i) === 'u') {
            i += 3
            length++
            d++
            if (d % 2 === 0) {
              length--
            }
          }
          i++
        }
      }
      disabled.value = length <= 15
    }

    return {
      isCollapse,
      tenantName,
      disabled,
      handleOperation,
      tenantSwitchDialogRef
    }
  }
})
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  //ドロップダウンのスタイル
  .el-dropdown-menu {
    top: 0;
    left: 0;
    z-index: 10;
    border-radius: 4px !important;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2) !important;
    padding: unset !important;
    -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
  .el-dropdown-menu__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 45px;
    background-color: white !important;
    font-size: 10px;
    border-radius: 0;
    border-top: 1px solid black;
    color: #333 !important;
    border-top: 1px solid #ccc;
  }
  .el-dropdown-menu--small .el-dropdown-menu__item {
    padding: 14px 24px !important;
    font-size: 14px;
  }
  .sidebar-tenant-container {
    display: block;
    height: 72px;
    padding: 16px 0 16px 20px;
    border-bottom: 1px solid #CCCCCC;
    background-color: #fff !important;
    .tenant-text {
      color: #333;
      height: 24px;
      font-size: 14px;
      font-weight: 700;
      line-height: 14px;
      display: inline-block;
      float: left;
      overflow:hidden;
      text-overflow:ellipsis;
      white-space:nowrap;
    }
    .sidebar-dropdown-button {
      vertical-align: middle;
    }
    .tenant-title {
      font-weight: 500;
      font-size: 12px;
      width: 100%;
    }
    .tenant-name {
      width: calc(100% - 55px);
      padding-top: 2px;
    }
    .tenant-button_collapse {
      width: 35px !important;
    }
  }
  .tenant-sidebar_collapse {
    padding: 20px 0 5px 0;
  }

</style>
