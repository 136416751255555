import { h } from 'vue'
import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import { Utils } from '@/utils'
import { store } from '@/common/store'
import Qs from 'qs'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import Cookies from 'js-cookie'
import { Messages } from '@/utils/messages'
import { event } from '../router/router_event'

NProgress.configure({ showSpinner: false })// NProgress configuration

const service = axios.create({
  baseURL: import.meta.env.VITE_BASE_API, // api base_url
  timeout: 35 * 10 ** 3, // msec
  withCredentials: true,
  paramsSerializer: function(params) {
    return Qs.stringify(params)
  },
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    'Access-Control-Allow-Origin': import.meta.env.VITE_BASE_API,
    'Access-Control-Allow-Credentials': true
  }
})

let replaceUrl = ''

// Request Interceptor
service.interceptors.request.use(
  config => {
    if (config.mode !== 'silent') {
      NProgress.start()
    }
    if (config && config.url !== '/request/auth') {
      const uuid = Utils.guid()
      sessionStorage.setItem('X-REQUEST-ID', uuid)
      config.headers['X-REQUEST-ID'] = uuid
    }
    const token = Cookies.get('VMSANALYSIS_LOGIN_TOKEN')
    const tenantId = Cookies.get('TEANANT_ID')
    replaceUrl = ''
    if (token) {
      // 二重ログインの処理
      handleTwoLogin(token)

      // 二つtabでテナント切り替えの処理
      handleTwoTab(tenantId)
    }
    if (window.isNeedRefreshFlag) {
      ElMessageBox.confirm(
        '画面の再読み込みを行います。',
        'Notification',
        {
          confirmButtonText: 'OK',
          confirmButtonClass: 'el-button--medium el-message-box-confirm-btn',
          showCancelButton: false,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          closeOnHashChange: false,
          showClose: false,
          type: ''
        }
      ).then(() => {
        window.isNeedRefreshFlag = false
        store.dispatch('clearStorage').then(() => {})
        if (replaceUrl !== '') {
          location.replace(replaceUrl)
        } else {
          location.reload()
        }
      })
      throw new Error()
    }

    return config
  },
  error => {
    // Do something with request error
    Promise.reject(error)
  }
)

// response Interceptor
service.interceptors.response.use(
  response => {
    NProgress.done()
    let res = response.data
    if (response.config.responseType === 'arraybuffer') {
      const contentType = response.headers['content-type']
      if (contentType.startsWith('application/json')) {
        const data = String.fromCodePoint.apply(null, new Uint8Array(res))
        res = JSON.parse(decodeURIComponent(escape(data)))
      } else {
        Utils.download(response)
        return Promise.resolve(res)
      }
    }

    if (res.statusCode !== '20000') {
      return handleBizError(res, response)
    } else {
      return res
    }
  },
  error => {
    let message = ''
    if (error && error.message === 'Network Error') {
      message = 'インターネット接続異常。'
    } else {
      message = error.message
    }
    if (message) {
      setTimeout(function() {
        ElMessage({ showClose: true, message: message, type: 'error', duration: 0 })
      }, 500)
    }
    return Promise.reject(error)
  }
)

function handleTwoLogin(token) {
  // 二重ログインの処理
  if (sessionStorage.getItem('token') === null) {
    sessionStorage.setItem('token', token)
  }
  if (sessionStorage.getItem('token') !== token) {
    sessionStorage.setItem('token', token)
    window.isNeedRefreshFlag = true
  }
}

function handleTwoTab(tenantId) {
  // 二つtabでテナント切り替えの処理
  if (tenantId) {
    if (sessionStorage.getItem('tenantId') === null) {
      sessionStorage.setItem('tenantId', tenantId)
    }
    if (sessionStorage.getItem('tenantId') !== '' && sessionStorage.getItem('tenantId') !== tenantId) {
      sessionStorage.setItem('tenantId', tenantId)
      window.isNeedRefreshFlag = true
    }
  } else if (sessionStorage.getItem('tenantId') !== null && sessionStorage.getItem('tenantId') !== 'undefined' && sessionStorage.getItem('tenantId') !== '') {
    sessionStorage.setItem('tenantId', tenantId)
    window.isNeedRefreshFlag = true
    replaceUrl = '/tenant/list'
  }
}

function setMessageParam(res) {
  let type = ''
  let duration = 5000
  if (res.type === 'E') {
    type = 'error'
    duration = 0
  } else if (res.type === 'W') {
    type = 'warning'
    duration = 10000
  } else if (res.type === 'I') {
    type = 'success'
  } else {
    type = 'info'
  }
  return { type, duration }
}

function typeIsSI(res) {
  return res.type === 'S' || res.type === 'I'
}

function isDownload(response) {
  return response.config.url.endsWith('/devices/download') || response.config.url.endsWith('/applications/download') || response.config.url.startsWith('/media/videos/requests/') || response.config.url.startsWith('/media/images/requests/')
}

function setMessageContent(res, message) {
  if (res.statusCode === '50014') {
    message = 'セッションが切れました。'
  } else if (res.statusCode === '50016') {
    message = 'アクセス元が不正です。'
  }
  return message
}

function outputMessage(res, response) {
  let msg = ''
  const length = res.messageList.length
  res.messageList.forEach((m, index) => {
    msg += m.message
    if (index < length - 1) msg += '\n'
  })

  const __ret = setMessageParam(res)
  const type = __ret.type
  const duration = __ret.duration
  const requestId = sessionStorage.getItem('X-REQUEST-ID')
  const xRequestId = response.config.headers['X-REQUEST-ID']
  const msgVn = Messages.getVnMsg(h, msg.split('\n'))
  if (requestId === xRequestId || isDownload(response)) {
    // 業務エラーが発生場合
    setTimeout(function() {
      ElMessage({ showClose: true, dangerouslyUseHTMLString: false, message: msgVn, type: type, duration: duration })
    }, 200)
    if (typeIsSI(res)) {
      return res
    } else {
      event.emit('logDownloadList', [])
      return Promise.reject(res)
    }
  }
}

function handleBizError(res, response) {
  let message = ''
  message = setMessageContent(res, message)
  if (res.statusCode === '50014' || res.statusCode === '50016') {
    window.isNeedRefreshFlag = false
    ElMessageBox.confirm(
      message + '再度ログインしてください。',
      'ログアウト',
      {
        confirmButtonText: '再ログイン',
        confirmButtonClass: 'el-button--medium el-message-box-confirm-btn',
        showCancelButton: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        closeOnHashChange: false,
        showClose: false,
        type: ''
      }
    ).then(() => {
      store.dispatch('fedLogOut').then(() => {
        location.reload()
      })
    })
    return Promise.reject(res)
  } else if (res.statusCode === '50018') {
    window.isNeedRefreshFlag = false
    ElMessageBox.confirm(
      '再度ログインしてください。',
      'ログアウト',
      {
        confirmButtonText: 'ログインへ',
        confirmButtonClass: 'el-button--medium el-message-box-confirm-btn',
        showCancelButton: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        closeOnHashChange: false,
        showClose: false,
        type: ''
      }
    ).then(() => {
      store.dispatch('fedLogOut').then(() => {
        location.reload()
      })
    })
    return Promise.reject(res)
  } else if (res.statusCode === '20008' && res.custom === false) {
    return outputMessage(res, response)
  } else {
    return Promise.reject(res)
  }
}

export default service
