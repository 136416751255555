import { router } from '.'
import { store } from '@/common/store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import Cookies from 'js-cookie'
import { Messages } from '@/utils/messages'
import { isSbAccount } from '@/utils/permission'

NProgress.configure({ showSpinner: false })// NProgress configuration

const whiteList = ['/password/sendEmail', '/password/noticeSuccess', '/password/set', '/password/reset', '/password/setSuccess', '/password/resetSuccess']
router.beforeEach(async(to, from, next) => {
  NProgress.start()
  document.title = '映像解析PF - ' + to.meta.title

  setTimeout(() => Messages.closeAll(), 200)
  const token = Cookies.get('VMSANALYSIS_LOGIN_TOKEN')
  if (token) {
    // SBユーザーがテナントに関係ないURLを入力する時、ローカルストレージのログダウンロード情報をクリアします
    handleClearLogDownloadInfo(to)
    if (store.getters.authorities === null || store.getters.authorities.length === 0) {
      await store.dispatch('refreshAuth').then(res => {
        return handleRefreshAuth(to, next)
      }).catch(() => {
      })
    } else if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      const toLogin = to.path === '/login'
      const toTop = to.path === '/'
      await store.dispatch('healthCheck', toLogin || toTop).then(res => {
        if (toLogin) {
          next({ path: '/' })
        } else {
          next()
        }
      }).catch(() => {
        toLogin && next()
        toTop && next(`/login`)
        NProgress.done()
      })
    }
  } else {
    return handleNullToken(to, from, next)
  }
})

export function handleClearLogDownloadInfo(to) {
  if (isSbAccount() && (to.fullPath.startsWith('/account') || to.fullPath === '/tenant/list' ||
    to.fullPath.startsWith('/device/master/edgebox-kind') ||
    to.fullPath.startsWith('/device/master/management-module') ||
    to.fullPath.startsWith('/help') || to.fullPath.startsWith('/notice'))) {
    sessionStorage.setItem('tenantId', '')
    Cookies.remove('TEANANT_ID')
    store.dispatch('clearAllLogDownload')
  }
}

export function handleRefreshAuth(to, next) {
  if (to.fullPath === '/404' && to.redirectedFrom !== null) {
    next({ path: to.redirectedFrom, replace: true })
  } else {
    next('/')
  }
}

export function handleNullToken(to, from, next) {
  if ((whiteList.indexOf(to.path) !== -1 || to.path === '/login' || (whiteList.indexOf(from.path) !== -1) && to.path === '/404')) {
    next()
  } else {
    store.dispatch('clearStorage').then()
    next(`/login`)
  }
}

router.afterEach(() => {
  NProgress.done()
})
