import { getCurrentInstance } from "vue"

export function useEmitter() {
  const internalInstance = getCurrentInstance()

  function dispatch(componentName, eventName, ...params) {
    let parent = internalInstance?.parent
    while (parent && (!parent.type.name || parent.type.name !== componentName)) {
      parent = parent.parent
    }
    if (parent) {
      parent.emit(eventName, ...params)
    }
  }

  function broadcast(componentName, eventName, params) {
    internalInstance?.children.forEach((child) => {
      const name = child.type.name
      if (name === componentName) {
        child.emit(eventName, ...params)
      } else {
        broadcast(componentName, eventName, params)
      }
    })
  }

  return { dispatch, broadcast }
}