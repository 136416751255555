import LayoutNoMenu from '../../../layout/LayoutNoMenu.vue'

const masterAlgorithm = [
  {
    path: '/master/algorithm/list',
    name: 'Master_Algorithm_List',
    component: () => import('@/views/device/algorithm/main/algorithm_main.vue'),
    meta: {
      title: 'マスタAIアプリ',
      roles: ['SB_ADMIN', 'SB_OPR'],
      authorities: ['AUTH_ALGORITHM_LIST']
    }
  },
  {
    path: '/master/algorithm/create',
    name: 'Master_Algorithm_Create',
    component: () => import('@/views/device/algorithm/edit/algorithm_create.vue'),
    meta: {
      title: 'マスタAIアプリ新規登録',
      activeMenu: { path: '/master/algorithm/list', title: 'AIアプリマスタ管理' },
      roles: ['SB_ADMIN'],
      authorities: ['AUTH_ALGORITHM_CREATE']
    }
  },
  {
    path: '/master/algorithm/show',
    name: 'Master_Algorithm_Show',
    component: () => import('@/views/device/algorithm/detail/algorithm_show.vue'),
    meta: {
      title: 'マスタAIアプリ詳細',
      activeMenu: { path: '/master/algorithm/list', title: 'AIアプリマスタ管理' },
      roles: ['SB_ADMIN', 'SB_OPR'],
      authorities: ['AUTH_ALGORITHM_READ']
    }
  },
  {
    path: '/master/algorithm/version/create',
    name: 'Master_Algorithm_Version_Create',
    component: () => import('@/views/device/algorithm/version/version_create.vue'),
    meta: {
      title: 'バージョン作成',
      activeMenu: { path: '/master/algorithm/list', title: 'AIアプリマスタ管理' },
      roles: ['SB_ADMIN'],
      authorities: ['AUTH_ALGORITHM_VERSION_CREATE']
    }
  },
  {
    path: '/master/algorithm/version/edit',
    name: 'Master_Algorithm_Version_Edit',
    component: () => import('@/views/device/algorithm/version/version_edit.vue'),
    meta: {
      title: 'バージョン編集',
      activeMenu: { path: '/master/algorithm/list', title: 'AIアプリマスタ管理' },
      roles: ['SB_ADMIN'],
      authorities: ['AUTH_ALGORITHM_VERSION_DELETE']
    }
  },
  {
    path: '/master/algorithm/edit',
    name: 'Master_Algorithm_Edit',
    component: () => import('@/views/device/algorithm/edit/algorithm_edit.vue'),
    meta: {
      title: 'マスタAIアプリ情報編集',
      activeMenu: { path: '/master/algorithm/list', title: 'AIアプリ' },
      roles: ['SB_ADMIN'],
      authorities: ['AUTH_ALGORITHM_UPDATE']
    },
  }
]

const masterAlgorithmRouter = {
  path: '/master/algorithm',
  component: LayoutNoMenu,
  name: 'masterAlgorithm',
  hidden: true,
  meta: { title: 'マスタAIアプリ', svg: 'system' },
  children: [
    ...masterAlgorithm
  ]
}

export default masterAlgorithmRouter
